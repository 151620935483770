/* global _satellite */
/**
 * Returns all required selectors at once in a single object
 * @param {Object} emailComponent - Email component's Jquery element
 * @returns {Object} - Returns all the selectors used by the component
 */
function getSelectors(emailComponent) {
    let popupForm = emailComponent.find('.pd-email-signup-form');
    let errorElement = $(
        '.pd-email-signup-component .invalid-feedback'
    );
    let submitBtn = $('.pd-email-signup-component .signup-action-section button');
    let serverError = errorElement.data('serverError');

    return {
        emailComponent: emailComponent,
        serverEmailErrorEl: errorElement,
        submitBtn: submitBtn,
        serverError: serverError,
        popupForm: popupForm
    };
}

/**
 * Submits emails address to the server
 * @param {Object} selectors - jquery selectors to hide/show sections based on server response
 */
function submitForm(selectors) {
    let popupForm = selectors.popupForm;
    if (popupForm.find('input.is-invalid').length > 0) {
        return;
    }
    let popupFormData = popupForm.find('input[data-fsfo!="true"][data-checkbox-type!="multi-checkbox"]').serialize();
    for (let i = 0; i < $('.multi-group-checkbox').length; i++) {
        let multiCheckboxName = $('.multi-group-checkbox').eq(i).find('input').attr('name');
        let multiCheckboxValues = $('.multi-group-checkbox').eq(i).find('input:checkbox:checked[data-attribute-other!="true"]').map(function () {
            return this.value;
        })
        .get()
        .join(',');
        popupFormData = popupFormData + '&' + multiCheckboxName + '=' + multiCheckboxValues;
    }
    let urlToSubmit = popupForm.data('action-url');
    let popupFormMethod = popupForm.attr('method');
    let popupFormSourceID = popupForm.find('#sourceSystem').val().trim().replace(/\s+/g, '-')
                            .toLowerCase();
    $('.pd-email-signup-component-inner .emailsignupform-progress-loader').removeClass('d-none');
    $.ajax({
        url: urlToSubmit,
        method: popupFormMethod,
        data: popupFormData,
        success: function (response) {
            $('.pd-email-signup-component-inner .emailsignupform-progress-loader').addClass('d-none');
            if (response.status === true) {
                $('.success-message').removeClass('d-none');
                $('.pd-email-signup-component').addClass('d-none');
                _satellite.track('form-submit-success', {
                    id: [popupFormSourceID]
                });
            } else {
                $('.failure-message').removeClass('d-none');
                $('.pd-email-signup-component').addClass('d-none');

                if (response.errorType === 'generic') {
                    _satellite.track('error', {
                        errorMessage: [response.message],
                        errorType: ['generic']
                    });
                } else if (response.errorType === 'validation') {
                    _satellite.track('error', {
                        errorMessage: [response.message],
                        errorType: ['validation']
                    });
                }
            }
        },
        error: function (err) {
            $('.pd-email-signup-component-inner .emailsignupform-progress-loader').addClass('d-none');
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
        }
    });
}

/**
 * Initializes email signup component with event listeners
 */
function init() {
    $(document).ready(function () {
        let selectors = getSelectors($('.pd-email-signup-component'));
        selectors.submitBtn.on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            let popupForm = selectors.popupForm;
            // reCaptcha token generation
            var responseToken = '';
            var captchaEnabled = popupForm.data('captcha-enabled');
            var siteKey = popupForm.data('captcha-sitekey');
            var source = popupForm.data('captcha-source');

            if (captchaEnabled && siteKey) {
                $.getScript(
                    'https://www.google.com/recaptcha/api.js?render=' + siteKey,
                        function () {
                            grecaptcha.ready(function () {
                                grecaptcha.execute(siteKey, { action: source })
                                .then(function (token) {
                                    responseToken = token;
                                    $('input.captcha-pd-token-val').val(responseToken);
                                    submitForm(selectors);
                                    $('.grecaptcha-badge').css('visibility', 'hidden');
                                });
                            });
                        }
                );
            } else {
                submitForm(selectors);
            }
        });

        let pageBgImageUrl = $('.experience-commerce_assets-pageBackground img').attr('src');
        $('.storepage.SignUp').css('background-image', 'url(' + pageBgImageUrl + ')');
    });
}

module.exports = {
    init: init
};
