'use strict';

$(document).ready(function () {
    var validateForm = function () {
        this.checkForm();
        if (this.currentElements.parents('.checkout-container').length === 0 || (window.enableEnhancedCheckout && this.currentElements.parents('.checkout-login-container').length === 0)) {
            $(this.currentForm)
                .find('.btn')
                .attr('disabled', !this.valid());
            return;
        }
    };
    // Initialize form validation.
    if ($.validator) {
        $.validator.methods.pattern = function (value, element) {
            return (
                this.optional(element) ||
                new RegExp(element.pattern).test(value)
            );
        };

        $.validator.setDefaults({
            errorClass: 'is-invalid',
            onfocusout: function (element) {
                var $element = $(element);
                $element.addClass('is-dirty');
                $element.valid();
                validateForm.call(this);
                if ($element.hasClass('is-invalid')) {
                    $('body:not(.no-validate)').trigger('formFieldError', {
                        type: $element.attr('name'),
                        errorMessage: $element
                            .nextAll('.invalid-feedback')
                            .html()
                    });
                }
            },
            onclick: function (element) {
                if (!$(element).is('select')) {
                    if ($(element).hasClass('pd-form-custom-checkbox')) {
                        var $parentCont = $(element).parents('.checkbox-group');
                        var checkedCount = $parentCont.find('input[type="checkbox"]:checked').length;
                        if (checkedCount === 0) {
                            $parentCont.find('.invalid-feedback').show();
                            $parentCont.find('.invalid-feedback').show();
                            $(element).addClass('is-invalid');
                            $(element).attr('aria-invalid', true);
                        } else {
                            $parentCont.find('.invalid-feedback').hide();
                            $parentCont.find('.invalid-feedback').hide();
                            $parentCont.find('input[type="checkbox"]').removeClass('is-invalid');
                        }
                    }
                    $(element).valid();
                    validateForm.call(this);
                }
            },
            onkeyup: function (element) {
                var $element = $(element);
                if (
                    ($element.val() !== '' || $element.prop('required')) &&
                    $element.hasClass('is-dirty')
                ) {
                    $element.valid();
                }
                validateForm.call(this);
            },
            showErrors: function (errorMap, errorList) {
                this.defaultShowErrors();
                errorList.forEach(function (eachErrorObj) {
                    var $currentElement = $(eachErrorObj.element);
                    var errorMessage = $currentElement.data(
                        eachErrorObj.method === 'email'
                            ? 'pattern'
                            : eachErrorObj.method
                    );
                    if (errorMessage) {
                        $currentElement
                            .nextAll('.invalid-feedback')
                            .html(errorMessage);
                    }
                });
            },
            errorPlacement: function () {
                return true;
            },
            highlight: function (element, errorClass) {
                $(element).addClass(errorClass);
                if ($(element).hasClass('pd-form-field')) {
                    $(element).parents('.form-group').find('.pd-field-error-clear-icon').removeClass('hide');
                }
            },
            unhighlight: function (element, errorClass) {
                $(element).removeClass(errorClass);
                if ($(element).hasClass('pd-form-field')) {
                    $(element).parents('.form-group').find('.pd-field-error-clear-icon').addClass('hide');
                }
            }
        });
    }
    $('form')
        .find(
            'button:not(.modal-close, .cancel-new-payment, .add-payment, .check-card-js, .btn-daum-address-suggestion, .checkout-login-btn)'
        )
        .attr('disabled', true);
    $.each($('form:not(.payment-form)'), function (index, eachForm) {
        var $form = $(eachForm);
        $form.validate({
            ignore:
                '.adyen-checkout__card__holderName .adyen-checkout__input--text, .add-payment, .cancel-new-payment, :hidden'
        });
        $form.on('change input', 'input', function (input) {
            var element = $(input)[0];
            var event = document.createEvent('HTMLEvents');
            event.initEvent('keyup', true, false);
            if (element.dispatchEvent) {
                element.dispatchEvent(event);
            }
        });
        if (eachForm.checkValidity && eachForm.checkValidity()) {
            $(eachForm)
                .find('button')
                .removeAttr('disabled');
            $(eachForm)
                .parents('.checkout-container')
                .find(
                    '.next-step-button button:visible:not(.place-order, .submit-payment)'
                )
                .removeAttr('disabled');
        }
    });
    $('form').on('keypress', function (event) {
        if (event.which === 13) {
            $(this)
                .find(
                    'button:not(.cross-icon-button, .modal-close, .cancel-new-payment, .add-payment, .promo-code-btn, #gpay-button-online-api-id)'
                )
                .click();
        }
    });
    $(
        'form button:not(.notified-btn, .btn-storelocator-search, .cross-icon-button, .modal-close, .cancel-new-payment, .add-payment, .add-giftcert, .check-giftcert, .promo-code-btn, .signup-action-section .btn-submit, .check-card-js, .btn-daum-address-suggestion)'
    ).on('click', function () {
        var $currentform = $(this).parents('form');
        if (
            $currentform.hasClass('payment-form') &&
            $currentform.find('.is-invalid, .adyen-checkout__field--error')
                .length > 0
        ) {
            return;
        }
        $('form')
            .find('.form-control.is-invalid')
            .removeClass('is-invalid');
        if ($currentform.valid()) {
            if (!$currentform.hasClass('payment-form')
                && !$currentform.hasClass('pd-email-signup-form')) {
                $currentform.trigger('submit');
            }
        }

        if ($('[data-required-checkbox="true"]').length > 0) {
            for (let i = 0; i < $('[data-required-checkbox="true"]').length; i++) {
                var $checkboxGroup = $('[data-required-checkbox="true"]').eq(i);
                var checkedCount = $checkboxGroup.find('input:checkbox:checked').length;
                if (checkedCount === 0) {
                    $checkboxGroup.find('.invalid-feedback').show();
                    $checkboxGroup.find('input:checkbox').addClass('is-invalid');
                    $checkboxGroup.find('input:checkbox')
                    .attr('aria-invalid', true);
                }
            }
        }

        if ($('.invalid-feedback:visible, .adyen-checkout__field--error')[0]) {
            $('html, body').animate({
                scrollTop:
                    $(
                        $(
                            '.invalid-feedback:visible, .adyen-checkout__field--error'
                        )[0]
                    ).offset().top - 50
            });
        }
    });

    $('input[type="number"]').on('mousewheel', function (event) {
        event.preventDefault();
    });
    $('input[type="number"]').on('keydown', function (event) {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
        }
    });

    if (window.enableEnhancedCheckout) {
        $('div.checkout-login-container input').focus(function () {
            $('#loginContent').find('input').prop('required', true);
            $('#loginContent').find('div').prop('required', true);
        });
    }
});
