'use strict';

var carousel = require('./carousel');

const mediaQuery = window.matchMedia('(min-width: 1024px)');

/**
 * Init community cards carousel
 */
function initCardsCarousel() {
    $('.community-component .card-group').each(function () {
        var $this = $(this);

        var opts = {
            dots: true,
            customPaging: function (slider, i) {
                var ariaLabel = 'slide ' + (i + 1) + ' of ' + (slider.getDotCount() + 1);
                return '<div class="carousel-dots" role="tab" id=' + i + ' aria-label="' + ariaLabel + '" aria-pressed="true" tabindex="0"></div>';
            },
            slidesToShow: 2,
            slidesToScroll: 2,
            centerMode: false,
            centerPadding: '48px',
            arrows: false,
            responsive: [
                {
                    breakpoint: 1023.98,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: true,
                        swipeToSlide: false
                    }
                }
            ]
        };
        carousel.initCarousel(opts, $this);
        carousel.heightResize($this, 0, 'body-wrapper');
    });
}

/**
 * Initialize listeners for resize
 */
function init() {
    mediaQuery.addListener(initCardsCarousel);
    initCardsCarousel();
}

module.exports = {
    init: init
};
