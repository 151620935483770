    /* global _satellite */
'use strict';

var base = require('base/components/countrySelector');
var keyboardAccessibility = require('base/components/keyboardAccessibility');

require('../../icons/chevron.svg');
require('../../icons/plus.svg');
require('../../icons/minus.svg');
require('../../icons/cross.svg');
require('../../icons/logoModal.svg');
require('../../icons/globe.svg');
require('../../icons/location.svg');

var parse = require('url-parse');
let mobileMediaQuery = window.matchMedia('(max-width: 1023px)');

/**
 * Verify if there is a setlang param in URL and if it is different than the locale stored
 * in the cookie. If it is different, set the value of cookie to the value of 'setlang' param in URL
 */
function verifyCookieLocale() {
    var localeInUrl = parse(location.href, true).query.setlang;
    var localeInCookie = $.cookie('loc');
    if (localeInUrl && localeInUrl !== localeInCookie) {
        $.cookie('loc', localeInUrl, { expires: 365, path: '/' });
    }
}

/**
 * Allow click of external resource to not be prevented
 */
function changeCountry() {
    $('.js-country-change').unbind('click');
}

/**
 * Toggles between plus and minus icon for accordion
 */
function accordionToggle() {
    $(document).ready(function () {
        $('.accordion .nav-header').on('click', function () {
            $(this)
                .find('.icon-expand, .icon-collapsed')
                .toggleClass('d-none');
        });
    });
}

/**
 * Country selector dropdown links click handler
 */
function updateCountry() {
    $('.dropdown-selector a').unbind('click');
    $('.dropdown-selector a.list-link, .message-on-cart a.list-link, #countrySelectorModalNew .change-location, .language-selector-tooltip .language').click(function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        var localeCode = $(this).data('locale');
        var localeCurrencyCode = $(this).data('currencycode');
        var queryString = $('.page').data('querystring');
        var url = $('.dropdown-selector').data('url');
        var countryCode = $(this).data('countrycode');
        var orderID = $('.order-number-value').text().trim();
        var email = $('.order-summary-email').text().trim();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString: queryString,
                CurrencyCode: localeCurrencyCode,
                CountryCode: countryCode,
                action: action,
                orderID: orderID,
                email: email
            },
            success: function (response) {
                if (response && response.redirectUrl) {
                    $.cookie('UsrCountry', countryCode, { expires: 30, path: '/' });
                    window.location.href = response.redirectUrl;
                }
            }
        });
    });
}

/**
 * Store locale in Local Storage
 */
function storeLocale() {
    $('.dropdown-selector a').click(function (e) {
        e.preventDefault();

        var localeCode = $(this).data('locale');
        $.cookie('loc', localeCode, { expires: 365, path: '/' });
    });
}

/**
 * Show country selector modal
 */
function showModal() {
    $(document).ready(function () {
        var countrySelectorModal = $('#countrySelectorModal');
        if ($('#comingFromSGToHK').length > 0 && !sessionStorage.getItem('showCountrySelectorSgToHk')) {
            localStorage.removeItem('showCountrySelectorModal');
            sessionStorage.setItem('showCountrySelectorSgToHk', 'comingFromSGToHK');
        }

        // condition check user coming to EU MVP from Non MVP
        if ($('#comingFromMVPToNONMVP').length > 0 && !sessionStorage.getItem('showCountrySelectorMvpToNonMvp')) {
            localStorage.removeItem('showCountrySelectorModal');
            sessionStorage.setItem('showCountrySelectorMvpToNonMvp', 'comingFromMVPToNONMVP');
        }
        if (
            countrySelectorModal.length > 0 &&
            !localStorage.getItem('showCountrySelectorModal')
        ) {
            countrySelectorModal.modal('show');
        }
        if (
            $('#countrySelectorModalNew').length &&
            !localStorage.getItem('showCountrySelectorModal')
        ) {
            $('#countrySelectorModalNew').removeClass('d-none');
            $('.country-selector-modal-new-backdrop').removeClass('d-none');
        }
    });
}

/**
 * Store country selector modal state in session
 */
function storeInSession() {
    if (typeof Storage !== 'undefined') {
        localStorage.setItem('showCountrySelectorModal', false);
    }
}

/**
 * Stay on site click handler state stored in session
 */
function stayOnSite() {
    $(document).ready(function () {
        var isClicked = false;
        var showTooltip = false;

        $('.stay-on').on('click', function () {
            storeInSession();
            $('.language-tooltip').removeClass('d-none');
        });


        $('#countrySelectorModal').on('shown.bs.modal', function () {
            isClicked = true;
            showTooltip = true;
        });

        $('body').on('click', function () {
            if (isClicked) {
                storeInSession();
            }
            if (showTooltip) {
                $('.language-tooltip').removeClass('d-none');
                showTooltip = false;
            }
        });
    });
}

/**
 * Country Selector Accessibility
 */

keyboardAccessibility('.navbar-header .country-selector', {
    40: function ($countryOptions) { // down
        if ($(this).is(':focus')) {
            $countryOptions.first().focus();
        } else {
            $(':focus').parent().next().children('a')
            .focus();
        }
    },
    38: function ($countryOptions) { // up
        if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
            $(this).focus();
            $(this).removeClass('show');
        } else {
            $(':focus').parent().prev().children('a')
            .focus();
        }
    },
    27: function () { // escape
        $(this).focus();
        $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
    },
    9: function () { // tab
        $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
    }
},
function () {
    if (!($(this).hasClass('show'))) {
        $(this).addClass('show');
    }

    $(this).find('.accordion-country-selector').addClass('show');
    return $(this).find('.dropdown-menu').find('li a');
}
);

/**
 * User Icon Menu Accessibility
 */

keyboardAccessibility('.header-right .user-icon', {
    40: function ($userMenu) { // down
        if ($(this).find('a').first().is(':focus')) {
            $userMenu.first().focus();
        } else {
            $(':focus').next()
            .focus();
        }
    },
    38: function ($userMenu) { // up
        if ($userMenu.first().is(':focus') || $(this).is(':focus')) {
            $(this).focus();
            $(this).removeClass('show');
        } else {
            $(':focus').prev()
            .focus();
        }
    },
    27: function () { // escape
        $(this).focus();
        $(this).removeClass('show');
    },
    9: function () { // tab
        $(this).removeClass('show');
    }
},
function () {
    if (!($(this).hasClass('show'))) {
        $(this).addClass('show');
    }
    $(this).find('.popover-bottom').addClass('show');
    $(this).focus();
    return $(this).find('.link-wrapper').find('a');
}
);

/**
 * Generates the mobile Language selector HTML
 * @returns {Object} languageSelector
 */
function generateLanguageSelector() {
    var languageSelector;
    if ($('.header div.language-selector').length) {
        var languageURL = $('.header div.language-selector').data('url');
        languageSelector = $('<div>', { class: 'accordion nav-item dropdown-selector language-selector d-lg-none', 'data-url': `${languageURL}`, tabIndex: '0', role: 'presentation' });

        // Selected Language logic starts
        var selectedLanguage = $('#dropdownLanguageSelector').data('languageid');
        var selectedLanguageLink = $('<a>', { class: 'accordion-item list-link', href: `${'#'}` });
        var selectedLanguageName = $('<span>', { class: 'selected-language-js', text: `${selectedLanguage}` });
        selectedLanguageLink.append(selectedLanguageName);
        languageSelector.append(selectedLanguageLink);
        // Selected Language logic ends

        // Available Languages logic starts
        $('.header div.language-selector ul li a').each(function () {
            let desktopLanguageLink = $(this);
            let locale = desktopLanguageLink.data('locale');
            let countryCode = desktopLanguageLink.data('countrycode');
            let currencyCode = desktopLanguageLink.data('currencycode');
            let languageID = desktopLanguageLink.data('languageid') === 'zh' ? desktopLanguageLink.find('span.name').text() : desktopLanguageLink.data('languageid');

            let languageLinkArgs = {
                class: 'accordion-item list-link',
                href: `${'#'}`,
                'data-locale': `${locale}`,
                'data-countryCode': `${countryCode}`,
                'data-currencyCode': `${currencyCode}`
            };
            if (desktopLanguageLink.attr('rel')) {
                languageLinkArgs.rel = 'nofollow';
            }
            let languageLink = $('<a>', languageLinkArgs);
            let languageName = $('<span>', { class: 'language-option', text: `${languageID}` });
            languageLink.append(languageName);
            languageSelector.append(languageLink);
        });
        // Available Languages logic ends
    }

    return languageSelector;
}

/**
 * Generates the mobile Country selector HTML
 * @returns {Object} countrySelector
 */
function generateCountrySelector() {
    var countrySelectorParent = $('<ul>', {
        class: 'nav navbar-nav primary',
        id: 'country-selector-container'
    });
    var countrySelector;

    if ($('.header div.country-selector').length) {
        var countryURL = $('.header div.country-selector').data('url');
        countrySelector = $('<li>', { class: 'nav-item dropdown-selector country-selector d-lg-none primary-nav-item', 'data-url': countryURL });

        var icon = `<svg height="24" width="24" class="location-icon">
            <use xlink:href="#location"></use>
        </svg>`;

        var selectedCountryContainer = $('<span>', { class: 'country-and-lang' });

        // Selected Country logic starts
        var selectedCountryData = $('#dropdownCountrySelector').data('selectedCountry');
        var selectedCountryCode = $('#dropdownCountrySelector').data('currencycode');
        var selectedCountry = $('<a>', {
            class: 'nav-primary collapsed',
            'data-toggle': 'collapse',
            'data-target': '#subcategories-collapseOne',
            'aria-expanded': 'true',
            'aria-controls': 'subcategories-collapseOne',
            role: 'menuitem',
            href: `${'#'}` // eslint-disable-line no-script-url
        });

        var selectedCountryName = $('<span>', { class: 'selected-country-js', 'data-selected-country-code': `${selectedCountryData.countryCode}`, text: `${selectedCountryData.name}` });
        var selectedCountrySymbol = '';
        if (selectedCountryData.showCurrencySymbol) {
            selectedCountrySymbol = $('<span>', { text: ` (${selectedCountryCode})` });
        }

        var nextArrowContainer = $('<span>', {
            class: 'next-arrow d-lg-none d-xl-none',
            'data-toggle': 'collapse',
            'data-target': '#subcategories-collapseOne',
            'aria-controls': 'subcategories-collapseOne'
        });

        var svgNext = `<svg height="14" width="14" class="location-icon">
            <use xlink:href="#rightArrow"></use>
        </svg>`;

        nextArrowContainer.append(svgNext);

        selectedCountry.append(selectedCountryName, selectedCountrySymbol);
        selectedCountryContainer.append(selectedCountry);
        // Selected Country logic ends

        // Available Countries logic starts
        var avlCountryParent = $('<div>', { class: 'd-sm-block d-md-block d-lg-none pdMobileMegaNavWrapper' });
        var availableCountries = $('<ul>', {
            id: 'subcategories-collapseOne',
            class: 'secondary collapse country-list',
            'aria-labelledby': 'accordionCountrySelector',
            'data-parent': '#country-selector-container',
            role: 'none',
            'aria-hidden': 'true'
        });

        let acHeader = $('<li>', { class: 'other-header nav-item location-header' });
        let headerContainer = $('<span>');
        let svgPrev = `<svg height="16" width="16" class="location-icon">
        <use xlink:href="#leftArrow"></use>
        </svg>`;
        let headerVal = $('#location-lang').val();
        let headerName = `<span>${headerVal}</span>`;
        let closeIcon = `<svg height="16" width="16" class="close-menu-link close-location icon">
        <use xlink:href="#close"></use>
        </svg>`;

        headerContainer.append(svgPrev, headerName, closeIcon);
        acHeader.append(headerContainer);

        let selectedCountryItem = $('<li>', {
            class: 'nav-item selected-country',
            role: 'menu-item'
        });
        let selectedCountryLink = $('<a>', {
            class: 'accordion-item list-link',
            href: `${'#'}` // eslint-disable-line no-script-url
        });
        var selectedCountryName2 = $('<span>', { class: 'selected-country-js', 'data-selected-country-code': `${selectedCountryData.countryCode}`, text: `${selectedCountryData.name}` });
        var selectedCountrySymbol2 = '';
        if (selectedCountryData.showCurrencySymbol) {
            selectedCountrySymbol2 = $('<span>', { class: 'selected-currency-js', text: ` (${selectedCountryCode})` });
        }

        selectedCountryLink.append(selectedCountryName2, selectedCountrySymbol2);
        selectedCountryItem.append(selectedCountryLink);

        let $langs = generateLanguageSelector();
        if ($langs !== undefined) {
            $langs[0].className = 'lang-links';
            selectedCountryItem.append($langs);
        }

        availableCountries.append(acHeader, selectedCountryItem);
        $('.header div.country-selector ul li a').each(function () {
            let desktopCountryLink = $(this);
            if (desktopCountryLink.hasClass('change-country-link')) {
                let moreLocations = $('<li>', { class: 'nav-item more-locations' });
                let countryGlobal = $('<a>', { class: 'change-country-link accordion-item js-country-change', href: `${desktopCountryLink.attr('href')}` });
                let globeIcon = `<svg class="next-arrow ctaArrow" height="16" width="16">
                                    <use xlink:href="#lululemonCtaArrow"></use>
                                </svg>`;
                let countryLabel = $('<span>', { class: 'change-country-label', text: `${desktopCountryLink.data('label-morelocations')}` });

                countryGlobal.append(countryLabel, globeIcon);
                moreLocations.append(countryGlobal);
                availableCountries.append(moreLocations);
                avlCountryParent.append(availableCountries);
            } else {
                let locale = desktopCountryLink.data('locale');
                let countryCode = desktopCountryLink.data('countrycode');
                let currencyCode = desktopCountryLink.data('currencycode');
                let countryLinkArgs = {
                    class: 'accordion-item list-link',
                    href: `${'#'}`,
                    'data-countryCode': `${countryCode}`,
                    'data-locale': `${locale}`,
                    'data-currencyCode': `${currencyCode}`
                };
                if (desktopCountryLink.attr('rel')) {
                    countryLinkArgs.rel = 'nofollow';
                }

                let linkListItem = $('<li>', {
                    class: 'nav-item',
                    role: 'menu-item'
                });
                let countryLink = $('<a>', countryLinkArgs);
                let availableCountryName = $('<span>', { text: `${desktopCountryLink.find('span.country-name').text()}` });
                let availableCountrySymbol = $('<span>', { class: 'currency_symbol', text: ` (${currencyCode})` });

                countryLink.append(availableCountryName, availableCountrySymbol);

                // let $languages = generateLanguageSelector();
                // $languages[0].className = 'lang-links';
                // linkListItem.append(countryLink, $languages);
                // availableCountries.append(linkListItem);

                linkListItem.append(countryLink);
                availableCountries.append(linkListItem);
                avlCountryParent.append(availableCountries);
            }
        });

        // Available Countries logic ends
        countrySelector.append(icon, selectedCountryContainer, nextArrowContainer, avlCountryParent);
        countrySelectorParent.append(countrySelector);
    }

    return countrySelectorParent;
}

/**
 * Generate the mobile country & language selector html content
 * and replace it with div id="mobile-page-locale"
 */
function updateMobileSelector() {
    if ($('.header div.country-selector').data('customMobileSelectors')) {
        var countrySelector = generateCountrySelector();
        var languageSelector = generateLanguageSelector();

        if (countrySelector || languageSelector) {
            countrySelector.find('.country-and-lang').append(languageSelector);
            $('#mobile-page-locale').replaceWith(countrySelector);
        }
    }
}

window.addEventListener('load', function () {
    $('.contry-selector-popup-select').selectpicker();

    if (localStorage.getItem('showCountrySelectorModal') === null && $('#countrySelectorModalNew').length === 0 && $('#countrySelectorModal').length === 0) {
        localStorage.setItem('languageTooltipShow', 'true');
    }

    if (localStorage.getItem('languageTooltipShow') === 'true') {
        $('.language-tooltip').removeClass('d-none');
    }

    $('.country-selector-modal-new .close').on('click', function () {
        $('.country-selector-modal-new').addClass('d-none');
        $('.country-selector-modal-new-backdrop').addClass('d-none');
        storeInSession();
        $('.language-tooltip').removeClass('d-none');
        if (typeof _satellite !== 'undefined') {
            _satellite.track('country-selector-close');
        }
    });

    $('.country-selector-modal-new .stay-on').on('click', function () {
        $('.country-selector-modal-new').addClass('d-none');
        $('.country-selector-modal-new-backdrop').addClass('d-none');
        storeInSession();
        $('.language-tooltip').removeClass('d-none');
        if (typeof _satellite !== 'undefined') {
            _satellite.track('country-selector-stayon', {
                region: $(this).data('country-code')
            });
        }
    });

    $('.language-selector-tooltip .close').on('click', function () {
        $('.language-tooltip').addClass('d-none');
        localStorage.removeItem('languageTooltipShow');
        storeInSession();
        if (typeof _satellite !== 'undefined') {
            _satellite.track('language-selector-close', {
                language: $('.language-selector-tooltip .selected').data('locale')
            });
        }
    });

    $('.language-selector-tooltip .selected').on('click', function () {
        $('.language-tooltip').addClass('d-none');
        localStorage.removeItem('languageTooltipShow');
        storeInSession();
    });

    $(document).on('change', '#countrySelectorModalNew .form-control.contry-selector-popup-select', function () {
        var data = $('#countrySelectorModalNew .form-control.contry-selector-popup-select').find(':selected').data();
        $('#countrySelectorModalNew .change-location').attr({
            'data-countryCode': data.countrycode,
            'data-locale': data.locale,
            'data-currencyCode': data.currencycode
        });
    });

    $('#countrySelectorModalNew .change-location').on('click', function () {
        localStorage.setItem('languageTooltipShow', 'true');
        storeInSession();
        if (typeof _satellite !== 'undefined') {
            _satellite.track('country-selector-change-location', {
                region: $(this).data('countrycode')
            });
        }
    });
    $('.language-selector-tooltip .language').on('click', function () {
        localStorage.removeItem('languageTooltipShow');
        storeInSession();
        if (typeof _satellite !== 'undefined') {
            _satellite.track('language-selector-change-language', {
                language: $(this).data('locale')
            });
        }
    });
    $('.see-all-locations .link').on('click', function () {
        storeInSession();
        localStorage.setItem('languageTooltipShow', 'true');
        _satellite.track('country-selector-see-all-locations');
    });
    $('.choose-site').on('click', function () {
        localStorage.setItem('languageTooltipShow', 'true');
    });

    /**
     * Focus on new country selector popup
     */
    function focusOnNewModal() {
        if ($('#countrySelectorModalNew a').first().hasClass('stay-on')) {
            $('#countrySelectorModalNew a').first().focus();
        } else {
            $('.dropdown-toggle').focus();
        }
    }

    // accessibility fo new location popup
    if ($('#countrySelectorModalNew').length && !$('#countrySelectorModalNew').hasClass('d-none')) {
        $('#countrySelectorModalNew .see-all-locations .link').on('keydown', function (e) {
            if (e.which === 9) {
                e.preventDefault();
                $('#countrySelectorModalNew .close').focus();
            }
        });
        if (!mobileMediaQuery.matches) {
            $('#countrySelectorModalNew .close').on('keydown', function (e) {
                if (e.which === 9) {
                    e.preventDefault();
                    $('.menu .logo a').focus();
                }
            });
        }
        if ($('#onetrust-banner-sdk').length) {
            $('#onetrust-reject-all-handler, #onetrust-accept-btn-handler, .save-preference-btn-handler, .ot-pc-refuse-all-handler, #accept-recommended-btn-handler').on('click keypress', function () {
                focusOnNewModal();
            });
        } else {
            focusOnNewModal();
        }
    }
});

module.exports = {
    base: base,
    updateMobileSelector: updateMobileSelector,
    verifyCookieLocale: verifyCookieLocale,
    storeLocale: storeLocale,
    changeCountry: changeCountry,
    accordionToggle: accordionToggle,
    stayOnSite: stayOnSite,
    showModal: showModal,
    updateCountry: updateCountry
};
