'use strict';

require('../../icons/wishlistSolid.svg');
require('../../icons/wishlistSolidWhite.svg');
require('../../icons/share.svg');
require('../../icons/leftArrow.svg');
require('../../icons/rightArrow.svg');
require('../../icons/liveChat.svg');

/**
 * function to select carousel thumbnail based on selected item index
 * @param {number} selectedIndex - Selected item index
 */
function selectThumbnail(selectedIndex) {
    $('.product-thumbnails .thumb').removeClass('selected')
        .find('.thumbnail-link')
        .attr('aria-current', false);
    $('.product-thumbnails .thumb')
        .eq(selectedIndex)
        .addClass('selected')
        .find('.thumbnail-link')
        .attr('aria-current', true);
    $('.carousel-item-message-list .carousel-item-message')
        .removeClass('active')
        .eq(selectedIndex)
        .addClass('active');
}

/**
 * Toggle Carousel thumbnail selection
 */
function toggleCarouselThumbnail() {
    var totalItems = $(
        '.primary-images .carousel .carousel-inner .carousel-item:not(".d-none")'
    ).length;
    $(document).on('imageCountChanged', 'body', function () {
        totalItems = $(
            '.primary-images .carousel .carousel-inner .carousel-item:not(".d-none")'
        ).length;
        var activeItemIndex =
            $(
                '.primary-images .carousel .carousel-inner .carousel-item.active'
            ).index() + 1;
        if (activeItemIndex > totalItems) {
            $('.primary-images .carousel').carousel(totalItems - 1);
        }
    });
    $(document).on('click', '.product-thumbnails .thumb', function () {
        $('.product-thumbnails .thumb').removeClass('selected').find('.thumbnail-link').attr('aria-current', false);
        $(this).addClass('selected').find('.thumbnail-link').attr('aria-current', true);
        $('.carousel-item-message-list .carousel-item-message')
            .removeClass('active')
            .eq(
                $(this)
                    .find('.thumbnail-link')
                    .attr('data-slide-to')
            )
            .addClass('active');
    });

    $('body .product-thumbnails .thumb').on('keypress', function (e) {
        if (e.which === 13 || e.which === 32) {
            $(this).trigger('click');
        }
    });

    $('.product-wrapper .carousel').keydown(function (e) {
        if (e.keyCode === 37) {
            // Previous
            $(this)
                .find('.carousel-control-prev')
                .click();
        }
        if (e.keyCode === 39) {
            // Next
            $(this)
                .find('.carousel-control-next')
                .click();
        }
        return false;
    });

    /**
     * function to add swipe functionality to devices with width less than 1024px
     * @param {Object} mediaQuery - Media query for carousel touch
     */
    function widthChange(mediaQuery) {
        if (mediaQuery.matches) {
            $(document).on('touchstart', '.product-wrapper .carousel', function (touchEvent) {
                var xClick = touchEvent.originalEvent.touches[0].pageX;
                $(this).on('touchmove', function (event) {
                    var xMove = event.originalEvent.touches[0].pageX;
                    if (Math.floor(xClick - xMove) > 50) {
                        $('.carousel-control-next').trigger('click');
                    } else if (Math.floor(xClick - xMove) < -50) {
                        $('.carousel-control-prev').trigger('click');
                    }
                });
                $(document).on('touchend', '.product-wrapper .carousel', function () {
                    $(this).off('touchmove');
                });
            });
        }
    }
    if (matchMedia) {
        const mediaQuery = window.matchMedia('(max-width: 1023.98px)');
        mediaQuery.addListener(widthChange);
        widthChange(mediaQuery);
    }

    $(document).on('click', '.product-wrapper .carousel-control-next', function () {
        var activeItemIndex = $(
            '.primary-images .carousel .carousel-inner .carousel-item.active'
        ).index();
        activeItemIndex += 1;
        if (activeItemIndex === totalItems) {
            activeItemIndex = 0;
            $(this)
                .parent()
                .carousel(activeItemIndex);
        }
        selectThumbnail(activeItemIndex);
    });

    $(document).on('click', '.product-wrapper .carousel-control-prev', function () {
        var activeItemIndex = $(
            '.primary-images .carousel .carousel-inner .carousel-item.active'
        ).index();
        activeItemIndex -= 1;
        if (activeItemIndex < 0) {
            activeItemIndex = totalItems - 1;
            $(this)
                .parent()
                .carousel(activeItemIndex);
        }
        selectThumbnail(activeItemIndex);
    });

    $(document).on('click', '.primary-images .carousel-indicators li', function () {
        var activeItemIndex = $(this).attr('data-slide-to');
        selectThumbnail(activeItemIndex);
    });
}

/**
 * Displays the correct carousel image for product set details page when number of images are different for different variants
 */
function setImageNavUpdate() {
    var initialImages = [];
    var currentTotalImages;
    $('.set-items .set-item').each(function () {
        initialImages.push(
            $(this).find(
                '.primary-images .carousel .carousel-inner .carousel-item:not(".d-none")'
            ).length
        );
    });
    $(document).on('imageCountChanged', 'body', function () {
        currentTotalImages = [];
        $('.set-items .set-item').each(function (index) {
            var $setItem = $(this);
            currentTotalImages.push(
                $setItem.find(
                    '.primary-images .carousel .carousel-inner .carousel-item:not(".d-none")'
                ).length
            );
            var activeItemIndex =
                $setItem
                    .find(
                        '.primary-images .carousel .carousel-inner .carousel-item.active'
                    )
                    .index() + 1;
            if (activeItemIndex > currentTotalImages[index]) {
                $setItem
                    .find('.carousel')
                    .carousel(currentTotalImages[index] - 1);
            }
        });
    });

    $(document).on('click', '.set-item .carousel-control-next', function () {
        var $setItem = $($(this).closest('.set-item'));
        var index = $setItem.index() - 1;
        var activeItemIndex =
            $setItem
                .find(
                    '.primary-images .carousel .carousel-inner .carousel-item.active'
                )
                .index() + 1;
        if (
            currentTotalImages &&
            initialImages[index] > currentTotalImages[index] &&
            activeItemIndex === currentTotalImages[index]
        ) {
            $(this)
                .parent()
                .carousel(0);
        }
    });

    $(document).on('click', '.set-item .carousel-control-prev', function () {
        var $setItem = $($(this).closest('.set-item'));
        var index = $setItem.index() - 1;
        var activeItemIndex =
            $setItem
                .find(
                    '.primary-images .carousel .carousel-inner .carousel-item.active'
                )
                .index() - 1;
        if (
            currentTotalImages &&
            initialImages[index] > currentTotalImages[index] &&
            activeItemIndex < 0
        ) {
            $(this)
                .parent()
                .carousel(currentTotalImages[index] - 1);
        }
    });
}

$(window).on('load', function () {
    $('.image-container').hover(function () {
        $(this).find('.hover-image').addClass('lazyload').css('display', 'block');
    });
});
/**
 * Init to call functions
 */
function init() {
    toggleCarouselThumbnail();
    setImageNavUpdate();
}

module.exports = {
    init: init
};
