/* global _satellite */
/**
 * Returns all required selectors at once in a single object
 * @param {Object} emailComponent - Email component's Jquery element
 * @returns {Object} - Returns all the selectors used by the component
 */
function getSelectors(emailComponent) {
    let emailEl = emailComponent.find('.emailsignup');
    let popupForm = emailComponent.find('.email-signup-form');
    let errorElement = emailComponent.find(
        '.email-address-section .invalid-feedback'
    );
    let submitBtn = emailComponent.find('.signup-action-section button');
    let emailSignUpFormContainer = emailComponent.find(
        '.email-signup-form-container'
    );
    let emailTextContainer = emailComponent.find(
        '.email-signup-text-component'
    );
    let emailSubmittedContainer = emailComponent.find(
        '.email-signup-submitted'
    );
    let requiredNotice = emailComponent.find('.required-notice');
    let serverError = errorElement.data('serverError');
    let loader = emailSignUpFormContainer.find('.emailsignupform-progress-loader.footer-form-loader');

    return {
        emailComponent: emailComponent,
        emailEl: emailEl,
        serverEmailErrorEl: errorElement,
        submitBtn: submitBtn,
        emailSignUpFormContainer: emailSignUpFormContainer,
        emailSubmittedContainer: emailSubmittedContainer,
        emailTextContainer: emailTextContainer,
        serverError: serverError,
        requiredNotice: requiredNotice,
        popupForm: popupForm,
        loader: loader
    };
}

/**
 * Submits emails address to the server
 * @param {Object} selectors - jquery selectors to hide/show sections based on server response
 */
function submitForm(selectors) {
    let popupForm = selectors.popupForm;
    let popupFormData = popupForm.serialize();
    let urlToSubmit = popupForm.data('action-url');
    let loader = selectors.loader;

    selectors.emailComponent
        .find('.email-signup-form')
        .spinner()
        .start();

    loader.removeClass('d-none');
    $.ajax({
        url: urlToSubmit,
        method: 'POST',
        data: popupFormData,
        success: function (data) {
            /* On successful submission, show thank you message
                On unsuccessful submission, show error state and message on input field */
            loader.addClass('d-none');
            if (data.success) {
                _satellite.track('email-signup-footer-success');
                selectors.emailSignUpFormContainer.hide();
                selectors.emailTextContainer.hide();
                selectors.emailSubmittedContainer.show();
                selectors.requiredNotice.hide();
            } else {
                selectors.emailEl.addClass('is-invalid');
                selectors.serverEmailErrorEl.html(selectors.serverError);
                $('body').trigger('emailSignupPayloadError', {
                    errorMessage: selectors.serverError
                });
            }
            selectors.emailComponent
                .find('.email-signup-form')
                .spinner()
                .stop();
        },
        error: function () {
            /* On error, show error state and message on input field */
            selectors.emailEl.addClass('is-invalid');
            selectors.serverEmailErrorEl.html(selectors.serverError);
            loader.addClass('d-none');
            $('body').trigger('emailSignupPayloadError', {
                errorMessage: selectors.serverError
            });
            selectors.emailComponent
                .find('.email-signup-form')
                .spinner()
                .stop();
        }
    });
}

/**
 * Initializes email signup component with event listeners
 */
function init() {
    $(document).ready(function () {
        $('.email-signup-component').each(function () {
            let selectors = getSelectors($(this));
            selectors.submitBtn.on('click', function (event) {
                event.preventDefault();
                _satellite.track('email-signup-footer-submit');
                let popupForm = selectors.popupForm;
                // reCaptcha token generation
                var responseToken = '';
                var captchaEnabled = popupForm.data('captcha-enabled');
                var siteKey = popupForm.data('captcha-sitekey');
                var source = popupForm.data('captcha-source');

                if (captchaEnabled && siteKey) {
                    $.getScript(
                        'https://www.google.com/recaptcha/api.js?render=' + siteKey,
                            function () {
                                grecaptcha.ready(function () {
                                    grecaptcha.execute(siteKey, { action: source })
                                     .then(function (token) {
                                         responseToken = token;
                                         $('input.captcha-footer-token-val').val(responseToken);
                                         submitForm(selectors);
                                         $('.grecaptcha-badge').css('visibility', 'hidden');
                                     });
                                });
                            }
                    );
                } else {
                    submitForm(selectors);
                }
            });
        });
    });
}

module.exports = {
    init: init
};
