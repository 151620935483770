'use strict';

require('../icons/close.svg');
require('../icons/callUs.svg');
require('../icons/emailUs.svg');
var Swiper = require('./components/thirdParty/swiper');
var carousel = require('./components/carousel');

/**
 * Checks if user have closed earlier with help of localstorage.
 */
function checkClose() {
    if (typeof Storage !== 'undefined') {
        if (!sessionStorage.getItem('closeBanner')) {
            $('.banner').removeClass('d-none');
        }
    } else {
        $('.banner').removeClass('d-none');
    }
}

/**
 * Banner stripe close click handler.
 */
function closeClick() {
    $('.site-stripe .close-btn').click(function (e) {
        e.preventDefault();
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem('closeBanner', false);
        }
        $('.banner').addClass('d-none');
    });
}

/**
 * Quick start popup handler.
 * @return {Object} - swiper object
 */
function quickStartPopup() {
    var myQuickStartSwiper;
    if ($('.quickStartWrapper').length > 0) {
        if (sessionStorage.getItem('disabledQuickStartFlag') === null) {
            sessionStorage.setItem('disabledQuickStartFlag', 'false');
        }
        setTimeout(function () {
            if ($('.quickStartWrapper .tile').length > 4) {
                $('.quickStartWrapper .swiper-wrapper').removeClass('center-tile');
                myQuickStartSwiper = new Swiper($('.quickStartWrapper .product-tile-wrapper'), {
                    slidesPerView: 4.5,
                    freeMode: true,
                    spaceBetween: 1,
                    breakpoints: {
                        1023: {
                            slidesPerView: 4.5,
                            slidesPerGroup: 1,
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }
                        },
                        1024: {
                            slidesPerView: 5,
                            slidesPerGroup: 1,
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }
                        }
                    }
                });
            } else {
                $('.quickStartWrapper .swiper-wrapper').addClass('center-tile');
                $('.quickStartWrapper .tile').removeClass('swiper-slide');
            }
        }, 300);
    }

    return myQuickStartSwiper;
}

/**
 * Site stripe countdown timer handler.
 * @param {Object} $timerWrapper - Countdown timer wrapper
 *  @param {string} componenttype - Countdown component type
 */
function siteStripeCountdownHandler($timerWrapper, componenttype) {
    var countdownTimerContentURL = $('.global-header').attr('data-countdownurl');
    var endDateParam = $timerWrapper.attr('data-enddate');
    var endTime = '';
    var $dateDisplay = $timerWrapper.find('.date-display');
    var $timerMainText = $timerWrapper.find('.main-text');
    var $timerSubText = $timerWrapper.find('.sub-text');
    var $timerUL = $timerWrapper.find('#countdown');
    var $altMainText = $timerWrapper.find('.alt-main-text');
    var $altSubText = $timerWrapper.find('.alt-sub-text');
    var $timerCampaignMsg = $timerWrapper.find('.timer-campaign-msg');
    var $hrsEle = $timerWrapper.find('.hours');
    var $minEle = $timerWrapper.find('.minutes');
    var $secEle = $timerWrapper.find('.seconds');

    if (endDateParam !== '') {
        $.ajax({
            type: 'GET',
            url: countdownTimerContentURL,
            data: {
                endDate: endDateParam,
                componenttype: componenttype
            },
            contentType: 'application/json; charset=utf-8',
            success: function (res) {
                if (res.countdownTimerConfig.campaignEnded) {
                    $timerCampaignMsg.addClass('d-none');
                    $timerUL.addClass('d-none');
                    $dateDisplay.addClass('d-none');
                    $timerMainText.addClass('d-none');
                    $timerSubText.addClass('d-none');
                    $altMainText.removeClass('d-none');
                    $altSubText.removeClass('d-none');
                    $('.pd_countdowntimer').removeClass('d-none');
                    $('.countdowntimerslot').removeClass('d-none');
                } else {
                    var hrsRes = res.countdownTimerConfig.hours ? res.countdownTimerConfig.hours : '0';
                    var minRes = res.countdownTimerConfig.minutes ? res.countdownTimerConfig.minutes : '0';
                    var secRes = res.countdownTimerConfig.seconds ? res.countdownTimerConfig.seconds : '0';
                    if (res.countdownTimerConfig.days) {
                        hrsRes = (res.countdownTimerConfig.days * 24) + hrsRes;
                    }

                    endTime = hrsRes + ':' + minRes + ':' + secRes;
                    var tt = endTime.split(':');
                    var secVal = (tt[0] * 3600) + (tt[1] * 60) + (tt[2] * 1);
                    var timeDiffInSecond = secVal; // in second

                    if (timeDiffInSecond > 0) {
                        const x = setInterval(function () {
                            $altMainText.addClass('d-none');
                            $altSubText.addClass('d-none');
                            var hrsVal = Math.floor(((timeDiffInSecond % 31536000) % 86400) / 3600) + (Math.floor((timeDiffInSecond % 31536000) / 86400) * 24);

                            if (timeDiffInSecond < 86400) {
                                $dateDisplay.addClass('d-none');
                                $timerMainText.addClass('d-none');
                                $timerUL.removeClass('d-none');
                                $hrsEle.html(String(hrsVal).padStart(2, '0'));
                                $minEle.html(String(Math.floor((((timeDiffInSecond % 31536000) % 86400) % 3600) / 60)).padStart(2, '0'));
                                $secEle.html(String(Math.floor((((timeDiffInSecond % 31536000) % 86400) % 3600) % 60)).padStart(2, '0'));
                            } else if ($timerMainText.length > 0 && $timerMainText.html() !== '') {
                                $timerUL.addClass('d-none');
                                $timerMainText.removeClass('d-none');
                            } else {
                                $timerUL.addClass('d-none');
                                $dateDisplay.html(res.countdownTimerConfig.dateDisplay);
                                $dateDisplay.removeClass('d-none');
                            }
                            $timerWrapper.removeClass('d-none');

                            timeDiffInSecond -= 1;

                            if (timeDiffInSecond < 0) {
                                clearInterval(x);
                                $timerCampaignMsg.addClass('d-none');
                                $timerUL.addClass('d-none');
                                $dateDisplay.addClass('d-none');
                                $timerMainText.addClass('d-none');
                                $timerSubText.addClass('d-none');
                                $altMainText.removeClass('d-none');
                                $altSubText.removeClass('d-none');
                            }
                        }, 1000);
                    }
                }
            }
        });
    } else {
        $timerUL.addClass('d-none');
        $timerMainText.removeClass('d-none');
        $timerWrapper.removeClass('d-none');
    }
}


/**
 * Creates a site stripe carousel
 */
function initSiteStripeCarousel() {
    var $siteCarousel = $('.site-stripe-carousel');
    var isCountdownConfiguredFirst = $siteCarousel.find('.html-slot-container').first().hasClass('countdowntimerslot');

    var opts = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
        arrows: true,
        slide: 'div.html-slot-container',
        rows: 0,
        autoplay: false,
        swipeToSlide: true,
        infinite: true
    };

    if (isCountdownConfiguredFirst) {
        $siteCarousel.find('.html-slot-container').eq(1).hide();
        $siteCarousel.find('.html-slot-container').eq(2).hide();
    } else {
        carousel.initCarousel(opts, $siteCarousel);
    }

    setTimeout(function () {
        $('.site-stripe-slot').css('opacity', 1);
    }, 1600);
}

$(window).on('load', function () {
    if (window.cfCacheEnabled) {
        quickStartPopup();
    }
});

$(document).ready(function () {
    if ($('.pd_countdowntimer').length > 0) {
        $('.pd_countdowntimer').each(function () {
            siteStripeCountdownHandler($(this), 'pdcomponent');
        });
    }

    if ($('#countdowntimerslot').length > 0) {
        $('.countdowntimerslot').each(function (i, ele) {
            siteStripeCountdownHandler($(ele), 'slotcomponent');
        });
    }

    initSiteStripeCarousel();

    var siteSearch = document.getElementById('siteSearch');

    /**
     * finds the next or prev search Suggestion item
     * @param {Object} item - link item reference
     * @param {string} dir - direction next/prev
     * @returns {Object} - adjacent item next/prev
    */
    function nextDOMItem(item, dir) {
        var adjacentDropItem;

        var liElmIndex = $('#siteSearch [tabindex=0]').index(item);
        var lastInDrop = $('#siteSearch [tabindex=0]').last();

        if (dir === 'next') {
            adjacentDropItem = $('#siteSearch [tabindex=0]')[liElmIndex + 1];
            if (lastInDrop[0] === item) {
                adjacentDropItem = $('#siteSearch [tabindex=0]')[1];
            }
        } else if (dir === 'prev') {
            adjacentDropItem = $('#siteSearch [tabindex=0]')[liElmIndex - 1];
        }

        return adjacentDropItem;
    }

    /**
     * Keyboard stuff
     * @param {Object} keyVent - key reference
    */
    function keyboardSearchHandler(keyVent) {
        var target = keyVent.target;
        var which = keyVent.which;

        if (which === 40) { // DOWN
            // dropdown item
            var nextItem = nextDOMItem(target, 'next');

            if (nextItem) {
                keyVent.preventDefault();
                nextItem.focus();
            }
        } else if (which === 38) { // UP
            // dropdown item
            var prevItem = nextDOMItem(target, 'prev');

            if (prevItem) {
                keyVent.preventDefault();
                prevItem.focus();
            }
        }
    }

    $(siteSearch).delegate('[tabindex="0"]', 'keydown', keyboardSearchHandler);
});

module.exports = {
    checkClose: checkClose,
    closeClick: closeClick,
    quickStartPopup: quickStartPopup
};
