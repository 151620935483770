'use strict';

var responseToken = '';
var siteKey = $('.gift-card-section').data('captcha-sitekey');
var source = $('.gift-card-section').data('captcha-source');


$('.gift-card-modal .giftCard').on('input', function () {
    if ($(this).val() !== '') {
        $('.gift-card-modal .check-giftcert').removeAttr('disabled');
    } else {
        $('.gift-card-modal .check-giftcert').attr('disabled', true);
    }
});

$('.gift-card-modal .check-giftcert').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    grecaptcha.ready(function () {
        grecaptcha.execute(siteKey, { action: source }).then(function (token) {
            responseToken = token;
            var $giftCardSection = $('.gift-card-section');
            var $cardNumberSection = $giftCardSection.find('.card-number-section');
            var $balance = $('.gift-card-modal .balance');
            var $giftCardNumber = $giftCardSection.find('.giftCard');

            $giftCardSection.find('.is-invalid').removeClass('is-invalid');
            $giftCardSection.spinner().start();

            var url = $('.gift-card-modal .check-giftcert').attr('data-check-balance-url');
            $.ajax({
                url: url,
                method: 'GET',
                dataType: 'json',
                data: {
                    gToken: responseToken,
                    cardNumber: $giftCardNumber.val()
                },
                success: function (data) {
                    $giftCardNumber.removeClass('is-invalid');
                    if (data.success === 'true') {
                        $('.gift-card-modal .modal-dialog').addClass('has-balance');

                        if (window.enableEnhancedCheckout) {
                            $balance.html('<span class="gc-number">' + $giftCardNumber.val() + ':</span><span class="gc-balance">' + data.balance + '</span>');
                        } else {
                            $balance.html(data.balance);
                        }
                    } else {
                        $giftCardSection.find('.invalid-feedback').html(data.errorMsg);
                        $giftCardNumber.addClass('is-invalid');
                        $cardNumberSection.addClass('error');
                        $('body').trigger('formFieldError', {
                            type: 'checkGiftCardBalance',
                            errorMessage: data.errorMsg
                        });
                    }
                    $giftCardSection.spinner().stop();
                }
            });
        });
    });
});

$('.check-card-js').on('click', function (e) {
    e.stopPropagation();
    $('.gift-card-modal .modal-dialog').removeClass('has-balance');
});

$('.gift-card-modal').on('hidden.bs.modal', function () {
    var $giftCardModal = $('.gift-card-modal');
    $giftCardModal.find('.modal-dialog').removeClass('has-balance');
    $giftCardModal.find('.is-invalid').removeClass('is-invalid');
    $giftCardModal.find('.giftCard, .giftCardCode').val('');
    $giftCardModal.find('.balance').html('');
});
