/**
 * This file has been added to handle functions and events related to
 * Quick Add from CDP. Some of the functions have been copied from other files
 * and customized according to the needs for this feature.
 */

/* global _satellite */

'use strict';
var base = require('./baseNew');
var focusHelper = require('base/components/focus');

var selectedHybridSize;
/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog" aria-modal="true" tabindex="0">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev" tabindex="0" aria-label="previous"><span class="fa icon-prev" aria-hidden="true"><svg class="icon left-arrow" aria-label="previous"><use xlink:href="#leftArrow"></use></svg></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next" aria-label="next"><span class="fa icon-next" aria-hidden="true"><svg class="icon right-arrow" aria-label="previous"><use xlink:href="#rightArrow"></use></svg></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Adds event listener to dropdown menu on load
 */
function addScrollListener() {
    setInterval(function () {
        let el = $('#quickViewModal .dropdown-menu.inner');

        if (el) {
            el.on('scroll', function () {
                if (this.clientHeight + this.scrollTop + 4 >= this.scrollHeight) {
                    $(this).parent().siblings('.gradient').hide();
                } else {
                    $(this).parent().siblings('.gradient').show();
                }
            });
        }
    }, 100);
}

/**
 * Function for Satellite Track
 * @param {json}  prodJson - Contains data related to product loaded in quick add modal
 * @param {boolean} isQuickView - check if quick view modal was loaded or add to bag was clicked
 * @return {json} - value to be used when attributes got update for product
 */
function getProductSatelliteData(prodJson, isQuickView) {
    var productData = [];
    var productID;
    var productName;
    var color;
    var imageURL;
    var pdpURL;
    var stockStatus;
    var available;
    var sku;
    var primaryCategory;
    var unifiedID;
    var displayRegularPrice;
    var displayPrice;
    var isOnSale;

    if (isQuickView) {
        var formattedJSON = JSON.parse(prodJson.dataLayer);
        stockStatus = 'in-stock';
        imageURL = decodeURIComponent(formattedJSON.attributes.imageURL);
        pdpURL = decodeURIComponent(formattedJSON.attributes.pdpURL);
        available = formattedJSON.attributes.available;
        primaryCategory = formattedJSON.category.primaryCategory;
        unifiedID = formattedJSON.category.unifiedID;
        displayRegularPrice = formattedJSON.price.displayRegularPrice;
        displayPrice = formattedJSON.price.displayPrice;
        isOnSale = formattedJSON.price.onSale;
        productID = formattedJSON.productInfo.productID;
        productName = decodeURIComponent(formattedJSON.productInfo.productName);
        color = formattedJSON.productInfo.color;
        sku = formattedJSON.productInfo.sku;
    } else {
        imageURL = decodeURIComponent(prodJson.productAttributes.imageURL);
        pdpURL = decodeURIComponent(prodJson.productAttributes.pdpURL);
        available = prodJson.productAttributes.available;
        primaryCategory = prodJson.productCategory.primaryCategory;
        unifiedID = prodJson.productCategory.unifiedID;
        displayPrice = prodJson.productPrice.displayPrice;
        displayRegularPrice = prodJson.productPrice.displayRegularPrice;
        isOnSale = prodJson.productPrice.onSale;
        productID = prodJson.digitalProductData.productID;
        productName = decodeURIComponent(prodJson.digitalProductData.productName);
    }

    productData.attributes = {
        stockStatus: stockStatus,
        imageURL: imageURL,
        pdpURL: pdpURL,
        available: available // RETURN BOOLEAN true or false

    };
    productData.category = {
        primaryCategory: primaryCategory,
        unifiedID: unifiedID

    };
    productData.price = {
        displayRegularPrice: displayRegularPrice,
        displayPrice: displayPrice,
        onSale: isOnSale // RETURN BOOLEAN true or false

    };
    productData.productInfo = {
        productID: productID,
        productName: productName,
        color: color,
        sku: sku
    };

    return productData;
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {jQuery} quickViewBtn - HTML element
 * @param {boolean} component - string that indicates the component where quick shop was triggered
 */
function fillModalElement(selectedValueUrl, quickViewBtn, component) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-footer').html(parsedHtml.footer);
            $('#quickViewModal .full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl + '?' + data.queryString);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);

            // Display price on Add to cart CTA if there is a single size and is selected by default
            if (($('#quickViewModal .select-size .custom-select-btn option:selected').length || $('#quickViewModal .select-size .custom-select-btn input:checked').length) && $('#quickViewModal .swatch-circle-container.selected').length) {
                $('#quickViewModal .cta-price').html(' - ' + $('.pdp-swatches .swatch-circle-container.selected').closest('.price-groups').attr('data-attr'));
            } else {
                $('#quickViewModal .cta-price').html('');
            }


            $('#quickViewModal').modal('show');
            $('body').trigger('quickview:ready');

            var $productContainer = $('#quickViewModal .set-item');
            if (!$productContainer.length) {
                $productContainer = $('#quickViewModal .product-detail');
            }

            var imgs = data.product.images.large;
            createCarousel(imgs, $productContainer);

            $('.quickViewSelect').selectpicker();
            $('.quickViewSelect .dropdown-menu').append('<div class="gradient"></div>');
            $('div.quickViewSelect.select-color .dropdown-toggle').removeAttr('title');

            addScrollListener();
            $.spinner().stop();

            var ctaClicked = quickViewBtn.ariaLabel;
            var outfitPosition;
            var compValue;

            // Updating tracking data to record component details on Quick Shop modal
            if (component === 'product-compare') {
                $('#quickViewModal .add-to-cart-global').attr('data-compare', true);
            } else if (component === 'outfit-component') {
                $('#quickViewModal .add-to-cart-global').attr('data-outfit', true);
                $('#quickViewModal .full-pdp-link').attr('data-outfit', true);
                ctaClicked = quickViewBtn.closest('.bubble-wrapper') ? 'outfit-component:product-information-dot' : 'outfit-component:product-tile';
                $('#quickViewModal .add-to-cart-global').attr('data-hotspot', quickViewBtn.closest('.bubble-wrapper') ? 'true' : 'false');

                if (quickViewBtn.closest('.bubble-wrapper')) {
                    outfitPosition = $(quickViewBtn).closest('.outfit-component').find('.hotspot.active').attr('data-index');
                    $('#quickViewModal .full-pdp-link').attr('data-component', 'product-information-dot');
                } else {
                    outfitPosition = $(quickViewBtn).closest('.hotspot-outfit-slide').attr('data-index');
                    $('#quickViewModal .full-pdp-link').attr('data-component', 'product-tile');
                }

                compValue = { position: outfitPosition };
                $('#quickViewModal .add-to-cart-global').attr('data-tile-position', outfitPosition);
            }

            var productJSON = getProductSatelliteData(data, true);

            if (compValue) {
                productJSON.component = compValue;
            }

            _satellite.track('quickview-modal-viewed', {
                previousComponent: component,
                previousCTA: ctaClicked,
                product: productJSON
            });
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Overriding the function from pdpCarousel.js file
 * Handles carousel control in the quick view pop-up
 */
function toggleCarouselThumbnail() {
    var totalItems = $(
        '#quickViewModal .primary-images.quickViewImages .carousel .carousel-inner .carousel-item:not(".active")'
    ).length;
    $('body').on('imageCountChanged', function () {
        totalItems = $(
            '#quickViewModal .primary-images.quickViewImages .carousel .carousel-inner .carousel-item:not(".active")'
        ).length;
        var activeItemIndex =
            $(
                '#quickViewModal .primary-images.quickViewImages .carousel .carousel-inner .carousel-item.active'
            ).index() + 1;
        if (activeItemIndex > totalItems) {
            $('#quickViewModal .primary-images.quickViewImages .carousel').carousel(totalItems - 1);
        }
    });

    $('.product-wrapper .carousel').keydown(function (e) {
        if (e.keyCode === 37) {
            // Previous
            $(this)
                .find('.carousel-control-prev')
                .click();
        }
        if (e.keyCode === 39) {
            // Next
            $(this)
                .find('.carousel-control-next')
                .click();
        }
        return false;
    });
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('input[name=productID]').val();
    }

    return pid;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // show add to cart modal
    if ($('#addedToCartModal').length > 0) {
        $('.veil').remove();
        $('#addedToCartModal .modal-dialog').toggleClass('modal-dialog-centered', !jQuery.browser.mobile);
        $('#addedToCartModal .modal-title .quantity').html(response.productInforDirectCall.quantity);
        if (response.productInforDirectCall.quantity === '1') {
            $('#addedToCartModal .modal-title .quantity-single-text-js').show();
            $('#addedToCartModal .modal-title .quantity-multi-text-js').hide();
        } else {
            $('#addedToCartModal .modal-title .quantity-single-text-js').hide();
            $('#addedToCartModal .modal-title .quantity-multi-text-js').show();
        }
        $('#addedToCartModal .modal-body .quantity').html(response.quantityTotal);
        if (response.quantityTotal === 1) {
            $('#addedToCartModal .modal-body .quantity-single-text-js').show();
            $('#addedToCartModal .modal-body .quantity-multi-text-js').hide();
        } else {
            $('#addedToCartModal .modal-body .quantity-single-text-js').hide();
            $('#addedToCartModal .modal-body .quantity-multi-text-js').show();
        }

        if (response.productInfo) {
            var productInfo = response.productInfo;
            $('#addedToCartModal .modal-body .product-details .product-image img').attr('src', productInfo.images.large[0].url);
            $('#addedToCartModal .modal-body .product-details .product-content-name').html(productInfo.name);
            $('#addedToCartModal .modal-body .product-details .product-content-color').html(productInfo.color);
            $('#addedToCartModal .modal-body .product-details .product-content-size').html(selectedHybridSize);
            if (productInfo.sizeValue === 'one size') {
                $('#addedToCartModal .one-size-container,#addedToCartModal .other-size-container').addClass('oneSize');
            }
            if (productInfo.price) {
                var price = productInfo.price;
                $('#addedToCartModal .modal-body .product-details .product-content-price .markdown-prices').html(price.sales.formatted);
                if (productInfo.price.list && productInfo.price.sales.value !== productInfo.price.list.value) {
                    $('#addedToCartModal .modal-body .product-details .product-content-price .list-price').html(price.list.formatted);
                }
            }
            $('#addedToCartModal .modal-body .product-details .product-content a').attr('href', productInfo.helpURL);

            if (productInfo.sizeValue === 'one size') {
                $('.product-content-size').html(productInfo.size);
            } else {
                $('.product-content-size').html(selectedHybridSize);
            }
        }

        $('#addedToCartModal .modal-body .subtotal').html(response.cart.totals.subTotal);
        $('#addedToCartModal').modal();
        setTimeout(function () {
            $('body').addClass('modal-open');
            $('#addedToCartModal button.close')[0].focus();
            $('#addedToCartModal .product-carousel').slick('refresh');
        }, 500);
    }
}

$(document).on('hide.bs.dropdown', '#quickViewModal .form-control.select-size', function () {
    var isDefaultSelected = $('.form-control.select-size').find(':selected').hasClass('label-option');
    if (isDefaultSelected) {
        $('.invalid-msg').show();
        $('.form-control.select-size').addClass('invalid-state');
    }
});

$(document).on('change', '#quickViewModal .form-control.select-size', function () {
    var isDefaultSelected = $('#quickViewModal .form-control.select-size').find(':selected').hasClass('label-option');
    if (isDefaultSelected) {
        $('#quickViewModal .invalid-msg').show();
        $('#quickViewModal .form-control.select-size').addClass('invalid-state');
        $('#quickViewModal .form-control.select-size').addClass('default-opt');
    } else {
        $('#quickViewModal .invalid-msg').hide();
        $('#quickViewModal .form-control.select-size').removeClass('invalid-state');
        $('#quickViewModal .form-control.select-size').removeClass('default-opt');
    }
});

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');

        var $swatchButton = $attrValue.prop('tagName') === 'OPTION' ? $attrValue : $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);

            if ($attrValue.prop('tagName') === 'OPTION') {
                $swatchButton.val(attrValue.url);
            }
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');

        if ($attrValue.prop('tagName') === 'OPTION' && $attrValue.hasClass('unselectable')) {
            $attrValue.attr('disabled', true);
        } else {
            $attrValue.removeAttr('disabled');
        }

        if ($('#quickViewModal').length) {
            var parser = new DOMParser();
            var contentHtml = parser.parseFromString($attrValue.data('content'), 'text/html');
            var lowStockMsg = contentHtml.getElementsByClassName('stock-avail-msg')[0];
            if (lowStockMsg && attrValue.isVariantLowStock) {
                lowStockMsg.classList.remove('d-none');
                let contentString = contentHtml.getElementsByTagName('body')[0].innerHTML;
                $attrValue[0].dataset.content = contentString;
                $('.quickViewSelect').selectpicker('refresh');
                setTimeout(function () {
                    $('#' + attrValue.id).removeClass('d-none');
                    $('.' + attrValue.id).removeClass('d-none');
                }, 200);
            } else if (lowStockMsg && !lowStockMsg.classList.contains('d-none')) {
                lowStockMsg.classList.add('d-none');
                $('#' + attrValue.id).addClass('d-none');
                let contentString = contentHtml.getElementsByTagName('body')[0].innerHTML;
                $attrValue.attr('data-content', contentString);
            }

            if ($('.lowStockSingleColor').length && attrValue.selected) {
                if (attrValue.isVariantLowStock) {
                    $('.lowStockSingleColor').removeClass('d-none');
                } else {
                    $('.lowStockSingleColor').addClass('d-none');
                }
            }
        }
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption;
    if ($productContainer.find($attr + ' .select-' + attr.id + ' option:first').hasClass('label-option')) {
        $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:eq(1)');
    } else {
        $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    }
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

$(document).on('click keydown', '.dropdown-toggle', function () {
    if (this.ariaExpanded) {
        var $dropdown = $(this).siblings('.dropdown-menu').children('.inner').children('.dropdown-menu');
        if ($dropdown[0].scrollHeight === $dropdown[0].clientHeight) {
            $(this).siblings('.dropdown-menu').children('.gradient').hide();
        }
    }
});

/* Functions for keyboard accessibility in Quick Shop modal */

// Close modal when escape key is hit
$(document).on('keypress', function (e) {
    if (e.key === 'Escape' && $('#quickViewModal').hasClass('show')) {
        $('#quickViewModal').modal('hide');
    }
});

// Scroll size and color drop downs to the focused option
$(document).on('keydown', '#quickViewModal .attribute', function (e) {
    if ($('.dropdown-menu').is(':visible')) {
        var menu = $(this).find('ul.inner');
        var active = menu.find('li.active');

        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 9) {
            active[0].scrollIntoView();
        }
    }
});

// Send focus to previous image button if tabbed from add to cart button
$(document).on('keydown', '#quickViewModal .add-to-cart-global', function (e) {
    if (e.which === 9) {
        $('#quickViewModal .carousel-control-prev').focus();
    }
});

/**
 * Init to call functions
 */
function init() {
    toggleCarouselThumbnail();
}

module.exports = {
    init: init,
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            var quickShop = this;
            if ($(e.target).parents('.comparisonModal').length) {
                setTimeout(function () {
                    fillModalElement(selectedValueUrl, quickShop, 'product-compare');
                }, 800);
            } else if ($(e.target).parents('.outfit-component').length) {
                fillModalElement(selectedValueUrl, quickShop, 'outfit-component');
            } else {
                fillModalElement(selectedValueUrl, quickShop, undefined);
            }
        });
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .modal-dialog').focus();
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    availability: base.availability,
    addToCart: function () {
        $(document).on('click', '#quickViewModal button.add-to-cart,#quickViewModal button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            selectedHybridSize = $('div.select-size.quickViewSelect button.dropdown-toggle').attr('title');

            $(this).attr('disabled', true);

            if ($('.select-size').find('option:selected').length === 0 && $('.select-size').find('input:checked').length === 0) {
                $('.size-error-message').find('.error-message').show();
                $('.custom-select.select-size').addClass('error-highlights');
                return;
            }
            $('.size-error-message').find('.error-message').hide();

            var quantityValue = $('.quantity-select').find('option:selected').val();
            var itemStock = parseInt($('#item-stock-indicator').val(), 10);
            if (quantityValue > itemStock) {
                $('.quantity-error-message').find('.error-message').show();
                return;
            }
            $('.quantity-error-message').find('.error-message').hide();
            $('.custom-select.quantity-select').removeClass('error-highlights');

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        $('body').trigger('product:afterAddToCart', data);
                        handlePostCartAdd(data);
                        if (window.cfCacheEnabled) {
                            $.cookie('isDynamicData', true, { expires: 30, path: '/' });
                        }
                        var prodUrl = $('select.select-size').length ? $('#quickViewModal select.select-size').find(':selected').attr('data-url') : $('#quickViewModal input.options-select').val();
                        $.ajax({
                            url: prodUrl,
                            method: 'get',
                            dataType: 'json',
                            success: function (vData) {
                                var ctaClicked = $('#quickViewModal .add-to-cart-global').attr('aria-label');
                                var productJSON = getProductSatelliteData(vData, false);
                                var selectedSize = data.productInfo.size;
                                var selectedColor = data.productInfo.color;
                                var stockStatus;
                                var sku;
                                var componentVal;
                                var tilePosition;

                                // Checking where the quick shop modal is triggered from for tracking purposes
                                if ($('#quickViewModal .add-to-cart-global').attr('data-compare')) {
                                    componentVal = 'product-compare';
                                } else if ($('#quickViewModal .add-to-cart-global').attr('data-outfit')) {
                                    ctaClicked = $('#quickViewModal .add-to-cart-global').attr('data-hotspot') === 'true' ? 'outfit-component:product-information-dot' : 'outfit-component:product-tile';
                                    tilePosition = $('#quickViewModal .add-to-cart-global').attr('data-tile-position');
                                    var component = { position: tilePosition };
                                    productJSON.component = component;
                                    componentVal = 'outfit-component';
                                } else {
                                    componentVal = undefined;
                                }

                                var linkedProd = vData.linkedProduct;
                                for (var i = 0; i < linkedProd.length; i++) {
                                    if (linkedProd[i].productInfo.color === selectedColor && linkedProd[i].productInfo.size === selectedSize) {
                                        stockStatus = linkedProd[i].attributes.stockStatus;
                                        sku = linkedProd[i].productInfo.sku;
                                    }
                                }
                                productJSON.productInfo.color = selectedColor;
                                productJSON.productInfo.size = selectedSize;
                                productJSON.productInfo.sku = sku;
                                productJSON.attributes.stockStatus = stockStatus;

                                _satellite.track('quick add-modal-add-to-bag', {
                                    previousComponent: componentVal,
                                    previousCTA: ctaClicked,
                                    product: productJSON
                                });
                            },
                            error: function () {
                            }
                        });
                    },
                    error: function () {
                    }
                });
            }
        });
        var imageZoom = require('./imageZoom');
        imageZoom.getZoomImage();
    },
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    selectAttribute: function () {
        $(document).on('change', '#quickViewModal select[class*="select-"], .options-select', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
            $('div.quickViewSelect.select-color .dropdown-toggle').removeAttr('title');
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link')
                    .attr('href', response.data.product.selectedProductUrl);
            }
            if (($('#quickViewModal .select-size .custom-select-btn option:selected').length || $('#quickViewModal .select-size .custom-select-btn input:checked').length) && $('#quickViewModal .swatch-circle-container.selected').length) {
                $('#quickViewModal .cta-price').html(' - ' + $('#quickViewModal .prices .price .sales .value')[0].innerHTML.replaceAll('\n', '').trim());
            } else {
                $('#quickViewModal .cta-price').html('');
            }
            // Reducing the width of color name if low stock message is in view
            setTimeout(function () {
                if (!$('#quickViewModal div.select-color .filter-option-inner-inner .text-dark .stock-avail-msg').hasClass('d-none')) {
                    $('#quickViewModal div.select-color .filter-option-inner-inner .text-dark .name').addClass('half-width');
                    if ($('#currentSite')[0].value === 'KR' || $('#currentSite')[0].value === 'DE' || $('#currentSite')[0].value === 'ES') {
                        $('#quickViewModal div.select-color .filter-option-inner-inner .text-dark .name').addClass('quarter-width');
                    }
                } else if ($('#quickViewModal div.select-color .filter-option-inner-inner .text-dark .name').hasClass('half-width')) {
                    $('#quickViewModal div.select-color .filter-option-inner-inner .text-dark .name').removeClass('half-width');
                    if ($('#currentSite') === 'KR' || $('#currentSite') === 'DE' || $('#currentSite') === 'ES') {
                        $('#quickViewModal div.select-color .filter-option-inner-inner .text-dark .name').removeClass('quarter-width');
                    }
                }
            }, 200);

            if ($('#quickViewModal').length) {
                $('#quickViewModal div.quickViewSelect.select-color .dropdown-toggle').removeAttr('title');
            }
            $('[data-attr="color"] .pdp-swatches').each(function () {
                $(this).find('button').each(function () {
                    if ($(this).hasClass('selected')) {
                        $(this).attr('aria-pressed', 'true');
                    } else {
                        $(this).attr('aria-pressed', 'false');
                    }
                });
            });
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.add-to-cart-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
                || !$('.global-availability', dialog).data('available')
            );

            if (($('#quickViewModal .select-size .custom-select-btn option:selected').length || $('#quickViewModal .select-size .custom-select-btn input:checked').length) && $('#quickViewModal .swatch-circle-container.selected').length) {
                $('#quickViewModal .cta-price').html(' - ' + $('#quickViewModal .prices .price .sales .value')[0].innerHTML.replaceAll('\n', '').trim());
            } else {
                $('#quickViewModal .cta-price').html('');
            }
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);


            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    }
};
