/* eslint-disable no-undef */
'use strict';
var baseSearch = require('base/search/search');
var initVisualCarousel = require('../components/visualLinks');

let mobileMediaQuery = window.matchMedia('(max-width: 1023px)');

var firstLevelFilterEle = $('.firstLevelFilter .nav-item .nav-link');
var secondLevelFilterEle = $('.secondLevelFilter .experience-dynamic-filterTile');

var selectedSizes = false;
var recommendedFilters = false;
var carouselInit = require('../components/recommendationCarousel');
var productTile = require('../components/productTile');
var carousel = require('../components/carousel');

require('../../icons/bvstarsvg/star_0.1.svg');
require('../../icons/bvstarsvg/star_0.2.svg');
require('../../icons/bvstarsvg/star_0.3.svg');
require('../../icons/bvstarsvg/star_0.4.svg');
require('../../icons/bvstarsvg/star_0.5.svg');
require('../../icons/bvstarsvg/star_0.6.svg');
require('../../icons/bvstarsvg/star_0.7.svg');
require('../../icons/bvstarsvg/star_0.8.svg');
require('../../icons/bvstarsvg/star_0.9.svg');
require('../../icons/bvstarsvg/star_full.svg');
require('../../icons/bvstarsvg/staroutline.svg');

/**
 * Wishlist Status functionality
 */
function handleWishlistStatus() {
    if ($('.wishlistitems').text().length > 0) {
        if (localStorage.getItem('wishlistItems') === null) {
            localStorage.setItem('wishlistItems', '');
            localStorage.setItem('wishlistItems', JSON.stringify($('.wishlistitems').text().split(',')));
        }
    }

    var wishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
    for (var i = 0; i < wishlistItems.length; i++) {
        var $tileElement = $('#' + wishlistItems[i]);
        $tileElement.find('.wishlistTile').addClass('remove-from-wishlist');
        $tileElement.find('.wishlistTile').attr('title', $tileElement.find('.wishlistTile').data('remove-wishlist'));
        $tileElement.find('.icon-wishlist').removeClass('icon-wishlist-o');
        $tileElement.find('.icon-wishlist').addClass('icon-wishlist-s');
    }
}

/**
 * Show/hide einstein carousel based on filter and sort values
 */
function handleEinstein() {
    if ($('select[name=sort-order] .default-sort-option').length) {
        if ($('select[name=sort-order]').find(':selected').hasClass('default-sort-option')) {
            $('.recommendations-cdp').show();
            if ($('.recommendations-cdp .product-carousel.slick-initialized').length) {
                for (let i = 0; i < $('.recommendations-cdp .product-carousel.slick-initialized').length; i++) { // For both AI and einstein recommenders
                    $('.recommendations-cdp .product-carousel').get(i).slick.setPosition();
                }
            }
        } else {
            $('.recommendations-cdp').hide();
        }
    } else if ($('select[name=sort-order]').length && $('select[name=sort-order]').find(':selected')[0].index === 0) {
        $('.recommendations-cdp').show();
        if ($('.recommendations-cdp .product-carousel.slick-initialized').length) {
            for (let i = 0; i < $('.recommendations-cdp .product-carousel.slick-initialized').length; i++) { // For both AI and einstein recommenders
                $('.recommendations-cdp .product-carousel').get(i).slick.setPosition();
            }
        }
    } else {
        $('.recommendations-cdp').hide();
    }
}

$(document).ready(function () {
    $('.refinements').removeClass('d-none');
    $('.refinements').animate('slow', 100);
    if ($('.sort-custom-select').find('[selected]').length) {
        $('.sort-custom-select').find('[selected]').prop('selected', true);
    } else {
        $($('.sort-custom-select option')[0]).prop('selected', true);
    }
    handleEinstein();
    if ($('.pd-plp-hreflang-tags').children().length && $('[hreflang]').length) {
        var responseHrefLangLinks = $('.pd-plp-hreflang-tags').children();
        var domHrefLangLinks = $('[hreflang]');
        for (var i = 0; i < responseHrefLangLinks.length; i++) {
            for (var j = 0; j < domHrefLangLinks.length; j++) {
                if (responseHrefLangLinks[i].hreflang === domHrefLangLinks[j].hreflang && responseHrefLangLinks[i].href !== domHrefLangLinks[j].href) {
                    domHrefLangLinks[j].href = responseHrefLangLinks[i].href;
                    break;
                }
            }
        }
    } else if ($('.pd-refinement-based-seo').text() === 'true' && $('.pd-refinement-based-seo').text() !== null && $('.pd-plp-hreflang-tags').children().length === 0 && $('[hreflang]').length > 0) {
        $('[hreflang]').remove();
    }
});

// Updating data attributes for analytics on CDP recommender tiles.
$(window).on('load', function () {
    if ($('.recommendations-cdp .carousel-container .product a[data-lulu-track]').length) {
        let trackAttr = $('.recommendations-cdp .carousel-container .product a').attr('data-lulu-track');
        if (typeof trackAttr !== 'undefined' && trackAttr !== false) {
            $('.recommendations-cdp .carousel-container .product a').attr('data-lulu-track', 'cdp-recommendations-bestSellers');
        }
    }
});

// Tracking clicks on CDP recommender product tiles.
$(document).on('click', '.recommendations-cdp .carousel-container .product a[data-lulu-track="cdp-recommendations-bestSellers"]', function (event) {
    event.stopImmediatePropagation();
    var prodJSON = JSON.parse($(this).attr('data-lulu-attributes').replace(/'/g, '"'));
    prodJSON.attributes.position = parseInt($($(this).closest('.product-carousel-slide')).attr('data-slick-index'), 10) + 1;
    _satellite.track('cdp-recommendations-bestSellers', {
        product: prodJSON
    });
});

/**
 * See all functionality for refinements (Size/Colors)
 * On clicking see all link, hidden items will be shown & see all link will be removed
 */
function openSeeAll() {
    $(document).on('click', '.see-all-link', function (e) {
        e.preventDefault();

        // Get the link & its parent
        var seeAllLink = $(this);
        var seeAllLinkContainer = $(this).parent();
        var showLessLinkContainer = $(this).parentsUntil('.card-body').find('.show-less');

        // Get refinements container
        var listContainer = seeAllLink.closest('.content');

        // Find hidden refinements in the container & display them
        var hiddenListItems = listContainer.find('.see-all-item');
        hiddenListItems.toggleClass('d-lg-none');

        // Delete see all link from DOM
        seeAllLinkContainer.removeClass('d-lg-block');
        showLessLinkContainer.addClass('d-lg-block');
        setTimeout(function () {
            listContainer.find('.see-all-item').eq('0').find('a')[0].focus();
        }, 0);
    });

    $(document).on('click', '.show-less-link', function (e) {
        e.preventDefault();

        // Get the link & its parent
        var showLessLink = $(this);
        var showLessLinkContainer = $(this).parent();
        var seeAllLinkContainer = $(this).parentsUntil('.card-body').find('.see-all');

        // Get refinements container
        var listContainer = showLessLink.closest('.content');

        // Find hidden refinements in the container & display them
        var hiddenListItems = listContainer.find('.see-all-item');
        hiddenListItems.toggleClass('d-lg-none');

        // Delete see all link from DOM
        showLessLinkContainer.removeClass('d-lg-block');
        seeAllLinkContainer.addClass('d-lg-block');

        setTimeout(function () {
            listContainer.find('.see-all-link')[0].focus();
        }, 0);
    });
}

// quick filter classes
var firstLevelFilterClass = '.firstLevelFilter';
var secondLevelFilterClass = '.secondLevelFilter';

/**
 * Get Search Parameters from URL
 * @param {string} filterLevelClass - type of the filter
 * @return {undefined}
 */
function handleQuickFilter(filterLevelClass) {
    $('body').css('pointer-events', 'none');
    var classFilter = (filterLevelClass === '.firstLevelFilter') ? 'li a' : '.experience-dynamic-filterTile a.tile-copy-link';
    var $filter = $(filterLevelClass + ' ' + classFilter);
    var filterType;
    var firstItemSelected = true;
    var tileImageLink;
    for (var i = 1; i < $filter.length; i++) {
        var filterTypeSelector = $filter[i];
        filterType = $(filterLevelClass).find($(filterTypeSelector)).data('filter-type');
        if (filterType) {
            filterType = filterType.toLowerCase();
            var refinementFilter = $('.' + filterType + ' li a');
            var quickFilter = $($filter[i]);
            var quickFilterValue = quickFilter.data('filter-value');
            if (quickFilterValue && quickFilterValue !== 'All Filter Link') {
                // flag for show or hide the quick filter based on the left side refinement
                var hideFilter = true;
                for (var j = 0; j < refinementFilter.length; j++) {
                    var actualFilter = $(refinementFilter[j]);
                    var refinementFilterValue = $(refinementFilter[j]).data('filter-value');
                    if (quickFilterValue === refinementFilterValue) {
                        quickFilter.attr('href', actualFilter.attr('data-single-pushstate-url'));
                        quickFilter.attr('data-refinementurl', actualFilter.attr('data-singleurl'));
                        quickFilter.attr('data-hrefurl', actualFilter.attr('data-hrefurl'));
                        if (quickFilter.parent().hasClass('tile-component-inner')) {
                            tileImageLink = quickFilter.parent().find('.image-wrapper.tile-link');
                            tileImageLink.attr('href', actualFilter.attr('data-single-pushstate-url'));
                            tileImageLink.attr('data-refinementurl', actualFilter.attr('data-singleurl'));
                            tileImageLink.attr('data-hrefurl', actualFilter.attr('data-hrefurl'));
                        }
                        hideFilter = false;
                    }
                }
                quickFilter = (filterLevelClass === '.firstLevelFilter') ? quickFilter : quickFilter.parents('.experience-dynamic-filterTile');
                if (hideFilter) {
                    quickFilter.addClass('d-none');
                } else {
                    quickFilter.removeClass('d-none');
                }
            }
        }

        var refinementSelectedCount = $('.' + filterType + ' input[type="checkbox"]:checked').length;
        if ($('.firstLevelFilter').length > 0 && filterLevelClass === '.firstLevelFilter') {
            $('.firstLevelFilter').css('opacity', '1');
            if (filterType === $($('.firstLevelFilter .nav-item')[i]).find('.nav-link').data('filter-type').toLowerCase()) {
                if (refinementSelectedCount === 1) {
                    firstItemSelected = false;
                    $('ul.firstLevelFilter li:first-child .nav-link').removeClass('selected').attr('aria-selected', 'false');
                }
                if (firstItemSelected) {
                    $('ul.firstLevelFilter li:first-child .nav-link').addClass('selected').attr('aria-selected', 'true');
                    $('ul.firstLevelFilter li:not(:first-child) .nav-link').removeClass('selected').attr('aria-selected', 'false');
                } else if (refinementSelectedCount > 0) {
                    var slectedRefineDataFilterVal = $('.' + filterType + ' input[type="checkbox"]:checked').parents('a').data('filter-value');
                    $('ul.firstLevelFilter li').find('.nav-link[data-filter-value="' + slectedRefineDataFilterVal + '"]').addClass('selected').attr('aria-selected', 'true');
                }
            }
            $(firstLevelFilterClass).css('pointer-events', 'all');
        }

        if ($('.secondLevelFilter').length > 0 && filterLevelClass === '.secondLevelFilter' && i < $filter.length) {
            var secondLevelFilterType = $($('.secondLevelFilter .experience-dynamic-filterTile')[i]).find('a').data('filter-type').toLowerCase();
            if (filterType === secondLevelFilterType) {
                if (refinementSelectedCount === 1) {
                    firstItemSelected = false;
                    $('.secondLevelFilter .experience-dynamic-filterTile:first-child').removeClass('selected');
                }
                if (firstItemSelected) {
                    $('.secondLevelFilter .experience-dynamic-filterTile:first-child').addClass('selected');
                    $('.secondLevelFilter .experience-dynamic-filterTile:not(:first-child)').removeClass('selected');
                } else if (refinementSelectedCount > 0) {
                    var slectedRefineDataSecondLevelFilterVal = $('.' + filterType + ' input[type="checkbox"]:checked').parents('a').data('filter-value');
                    $('.secondLevelFilter').find('a[data-filter-value="' + slectedRefineDataSecondLevelFilterVal + '"]').parents('.experience-dynamic-filterTile').addClass('selected');
                }
            }
        }
    }

    if ($('.secondLevelFilter').length > 0) {
        var pdSwiperFilter = require('../components/pdSwiperFilter');
        pdSwiperFilter.init();
        $(secondLevelFilterClass).css('pointer-events', 'all');
        $('.secondLevelFilter').css('opacity', 1);
    }

    if ($('.collection-filter-swiper-init').length) {
        var activeSlides = $('.collection-filter-swiper-init .experience-dynamic-filterTile:not(.d-none)');
        activeSlides.each(function (k, e) {
            var tileLink = $(e).find('.tile-link');
            var dataLulu = JSON.parse(tileLink.attr('data-lulu-attributes').replace(/'/g, '"'));
            dataLulu.attributes.position = k + 1;
            tileLink.attr('data-lulu-attributes', JSON.stringify(dataLulu));
        });
    }
    $('body').css('pointer-events', 'all');
}

/**
 * Function to add Read more link to Top SEO content if the content exceeds 2 lines
 * @param {JQuery} $ele - DOM element for SEO text
*/
function styleSEOContent($ele) {
    for (var i = 0; i < $ele.length; i++) {
        var lines = $ele[i].getClientRects().length;
        var $seoParent = $ele.parents('.seo-parent');
        if (lines > 2 || (lines <= 2 && $ele.next('h2').length)) {
            if ($ele[0] === $('div.seo-bottom-rich-content')[0]) {
                let headerHeight = $seoParent.find($('.category-title')).outerHeight(true);
                let contentHeight = $seoParent.height();
                $seoParent.css('min-height', headerHeight + contentHeight);
            }
            $seoParent.siblings('.seo-gradient').removeClass('d-none');
            $seoParent.siblings('.expandable-btn-div').find('.showMore').removeClass('d-none');
        }

        if (lines <= 2 && $ele[0] === $('div.seo-bottom-rich-content')[0]) {
            let headerHeight = $seoParent.find($('.category-title')).outerHeight(true);
            let contentHeight = $seoParent.height();
            $seoParent.css('max-height', headerHeight + contentHeight);
        }
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector)
        .empty()
        .html($updates.html());
}
/**
 * Updates the meta data on the page when refinement is selected.
 * @param {string} response - Ajax response HTML code
 */
function updateMetaDataTags(response) {
    var $result = $(response);
    if ($result.find('.plp-page-title').text()) {
        document.title = $result.find('.plp-page-title').text();
    }
    if ($result.find('.plp-category-title').html()) {
        $('.category-title').html($result.find('.plp-category-title').html());
    }
    if ($result.find('.top-seo-content').html()) {
        $('.seo-rich-content').empty().html($result.find('.top-seo-content').html());
    }
    if ($result.find('.bottom-seo-content').html()) {
        $('.seo-bottom-rich-content').empty().html($result.find('.bottom-seo-content').html());
    }
    if ($result.find('.plp-page-description').html()) {
        document.querySelector('meta[name="description"]').setAttribute('content', $result.find('.plp-page-description').html());
    }
    if ($result.find('.plp-hreflang-tags').children().length && $('[hreflang]').length) {
        var responseHrefLangLinks = $result.find('.plp-hreflang-tags').children();
        var domHrefLangLinks = $('[hreflang]');
        for (var i = 0; i < responseHrefLangLinks.length; i++) {
            for (var j = 0; j < domHrefLangLinks.length; j++) {
                if (responseHrefLangLinks[i].hreflang === domHrefLangLinks[j].hreflang && responseHrefLangLinks[i].href !== domHrefLangLinks[j].href) {
                    domHrefLangLinks[j].href = responseHrefLangLinks[i].href;
                    break;
                }
            }
        }
    } else if ($('[hreflang]').length && $result.find('.plp-hreflang-tags').children().length === 0) {
        $('[hreflang]').remove();
    } else if ($('[hreflang]').length === 0 && $result.find('.plp-hreflang-tags').children().length > 0) {
        $('head').append($result.find('.plp-hreflang-tags').children());
    }
    if ($result.find('.plp-canonical-url').html()) {
        var links = document.getElementsByTagName('link');
        for (var k = 0; k < links.length; k++) {
            if (links[k].getAttribute('rel') === 'canonical') {
                links[k].href = $result.find('.plp-canonical-url').text();
                break;
            }
        }
    }
    if ($result.find('.plp-robots').html()) {
        document.querySelector('meta[name="robots"]').setAttribute('content', $result.find('.plp-robots').html());
    }
}
/**
 * ISBS-439 : Adds 'next' and/or 'prev' links to header
 *
 * @param {boolean} replace - replace previous next/pre link elements if true, exit function if next/pre found if false
 */
function addPaginationLinks(replace) {
    // Do this on CDPs only
    if ($('.search-results') < 1) {
        return;
    }
    if (!replace) {
        // If rel=next or rel=prev found exit
        if ($('head link[rel=next],[rel=prev]').length > 0) {
            return;
        }
    }
    var pageLinksMarkup = '';
    const currentRenderItems = $('.product-tile').length;
    const minRenderedItems = 12;
    const perItemsAfterButtonClick = 48;
    const totalResultsRegex = /\d+\s/g;
    const totalResultsHtml = $('.result-count').html();
    var totalResultsText = '';
    var totalResults = 0;

    if (totalResultsHtml) {
        totalResultsText = totalResultsHtml.match(totalResultsRegex);
        totalResults = parseInt(totalResultsText, 10);
    }

    var pagedSearchParams = new URLSearchParams(window.location.search);

    // Only do this if search results are more than 'minimum items rendered on page before auto pull'
    if (totalResultsHtml && (totalResults > minRenderedItems)) {
        // If current rendered items is less than 'button appears' threshold
        if (currentRenderItems > minRenderedItems) {
            if (currentRenderItems < perItemsAfterButtonClick) {
                pagedSearchParams.set('sz', currentRenderItems - minRenderedItems);
            } else {
                pagedSearchParams.set('sz', currentRenderItems - perItemsAfterButtonClick);
            }
            pageLinksMarkup += '<link rel="prev" href="https://' + window.location.hostname + window.location.pathname + '?' + pagedSearchParams.toString() + '" />';
        }
        if (currentRenderItems < totalResults) {
            if (currentRenderItems < perItemsAfterButtonClick) {
                pagedSearchParams.set('sz', currentRenderItems + minRenderedItems);
            } else {
                pagedSearchParams.set('sz', currentRenderItems + perItemsAfterButtonClick);
            }
            pageLinksMarkup += '<link rel="next" href="https://' + window.location.hostname + window.location.pathname + '?' + pagedSearchParams.toString() + '" />';
        }
        if (replace) {
            // Find previous rel links and remove them from the DOM.
            if ($('head link[rel=next],[rel=prev]').length > 0) {
                $('head link[rel=next],[rel=prev]').remove();
            }
        }

        $('head').append(pageLinksMarkup);
    }
}
/**
 * Expand/Collapse categories on click
 */
function handleCategories() {
    var selectedLink = $('.refinement-categories .values.content').find('.selected');
    var selectedCategoryLevel = selectedLink.parent().data('category-level');
    var selectedCategory = selectedLink.closest('.level' + selectedCategoryLevel);
    var selectedCategoryParent = selectedCategory.closest('.level' + (selectedCategoryLevel - 1));
    var selectedCategoryGrandparent = selectedCategoryParent.closest('.level' + (selectedCategoryLevel - 2));

    selectedCategory.removeClass('d-none');
    // don't show children category after level 3
    if (selectedCategoryLevel < 4) {
        selectedCategory.find('.level' + (selectedCategoryLevel + 1)).removeClass('d-none');
    }

    // if we select category level that is not 1
    if (selectedCategoryLevel !== 1) {
        if (selectedCategoryLevel < 4) {
            $('.level' + selectedCategoryLevel).not(selectedCategory).addClass('d-none');
        } else {
            $('.level' + selectedCategoryLevel).not(selectedCategory).removeClass('d-none');
        }
        selectedCategoryParent.removeClass('d-none');
        selectedCategoryGrandparent.removeClass('d-none');
    } else {
        // if category level is root, don't remove categories
        $('.level' + selectedCategoryLevel).removeClass('d-none');
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        $results
            .find('.' + $(this)[0].className.replace(/ /g, '.'))
            .addClass('active');
    });

    updateDom($results, '.refinements');
}
/**
 * Handle show and hide of hero banner on refinement selection.
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function handleHeroBannerUpdate($results, selector) {
    var $updates = $results.find(selector);
    if ($('.category-hero-slot').length > 0 && $updates.length > 0 && $updates.html().search('image-hero-banner') > 0) {
        $('.category-hero-slot').addClass('hide');
        $(selector).empty().html($updates.html()).removeClass('hide');
    } else if ($updates.length > 0 && $updates.html().search('image-hero-banner') === -1 && $('.category-hero-slot').length > 0) {
        $(selector).addClass('hide');
        $('.category-hero-slot').removeClass('hide');
    } else if ($('.category-hero-slot').length === 0) {
        $(selector).empty().html($updates.html());
    }
}
/**
 * Updates the Out of stock color variants on the page
 *
 * @param {Object} results - jQuery DOM element
 * @return {undefined}
 */
function updateOOSColorVariants(results) {
    var products = results.find('.product');
    var productIDs = [];
    var getOOSColorsUrl = $('.product-grid').data('oosurl');
    for (var i = 0; i < products.length; i++) {
        var product = products[i];
        var productID = product.dataset.pid;
        productIDs.push(productID);
    }
    productIDs = productIDs.join();
    $.ajax({
        url: getOOSColorsUrl,
        data: {
            pid: productIDs,
            selectedSizes: selectedSizes
        },
        type: 'post',
        success: function (response) {
            if (response.variantAvailabilityArray !== undefined) {
                var variantAvailabilityArray = response.variantAvailabilityArray;
                for (var j = 0; j < variantAvailabilityArray.length; j++) {
                    var prodID = '#' + variantAvailabilityArray[j].productID;
                    var availabilityMapArr = Object.entries(variantAvailabilityArray[j].availabilityMap);
                    $(prodID).find('.color-swatches a:not(.plusSymbol)').addClass('unselectable');
                    var colorSwatchesWrapper = $(prodID).find('.swatches');
                    colorSwatchesWrapper.css('opacity', '0');
                    if (colorSwatchesWrapper.hasClass('slick-initialized')) {
                        carousel.removeCarousel(colorSwatchesWrapper);
                    }
                    for (const [key, value] of availabilityMapArr) {
                        if (value && !value.available) {
                            $(prodID).find("[data-color-code='" + key + "']")
                            .addClass('unselectable').attr('aria-disabled', 'true');
                        } else if (value && value.available) {
                            $(prodID).find("[data-color-code='" + key + "']")
                            .removeClass('unselectable').removeAttr('aria-disabled', 'true');
                        }

                        if (value && value.available && value.isLowInStock) {
                            $(prodID).find("[data-color-code='" + key + "']").find('img').attr('data-islis', true);
                        }
                    }

                    if (colorSwatchesWrapper.hasClass('slick-initialized')) {
                        colorSwatchesWrapper.find('.slick-list').append($(prodID).find('.swatchAnchor.unselectable'));
                    } else {
                        colorSwatchesWrapper.append($(prodID).find('.swatchAnchor.unselectable'));
                    }

                    var $imgSwatchesElm = colorSwatchesWrapper.find('img.cdp-swatches');
                    if ($imgSwatchesElm.length > 1) {
                        $imgSwatchesElm.eq(0).trigger('click');
                        $imgSwatchesElm.eq(0).parents('.swatchAnchor').removeClass('selected');
                    }

                    var $firstSwatchImg = $(prodID).find('.color-swatches a.swatchAnchor').first().find('img');
                    var isLowInStock = $firstSwatchImg.data('islis');
                    if (isLowInStock) {
                        var lowInStockBadge = $firstSwatchImg.data('lis-msg');
                        var $imgContainer = $(prodID).find('.image-container');
                        $imgContainer.find('.promoCallout').addClass('d-none');
                        if (!$imgContainer.find('.lis-badge').length) {
                            $imgContainer.append('<div class="lis-badge">' + lowInStockBadge + '</div>');
                        }
                    }
                }
                // Adding timeout to load before initializing carousel
                setTimeout(function () {
                    productTile.initSwatch($('.swatches'));
                    $('.swatches').css('opacity', '1');
                }, 700);
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };
    if (selectedSizes && window.ffCdpOOSProduct) {
        updateOOSColorVariants($(response));
    }
    handleHeroBannerUpdate($results, '.refinement-hero-banner');
    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.content-grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.filter-header',
        '.nav-tabs-wrapper',
        '.sort-wrapper',
        '.dataLayer',
        '.size-chart',
        '.no-search-results-js',
        '.category-visual-link-slot',
        '.seo-content-update',
        '.seo-bottom-content-update',
        '.breadcrumb',
        '.einstein-recommender',
        '.ai-recommender'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });
    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}
/**
 * Updates the refinement url after page sorting.
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateRefinementsPostSorting(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };
    if ($results) {
        Object.keys(specialHandlers).forEach(function (selector) {
            specialHandlers[selector]($results);
        });
    }

    $('.mobFilterPills .filter-pills-wrapper').html($('.refinements .active-filter-list').html());
}
/**
 * Filter click handler
 */
function filter() {
    baseSearch.filter = function () {
        $('button.filter-results').unbind('click');
        // Display refinements bar when Menu icon clicked
        $('.container').on('click', 'button.filter-results', function () {
            recommendedFilters = false;
            $('.refinement-bar, .modal-background').addClass('show');
            $('body').addClass('filters-modal');
        });
    };
}

/**
 * Close refinments click handler
 */
function closeRefinments() {
    $('.refinement-bar button.close, .modal-background').unbind('click');
    baseSearch.closeRefinments = function () {
        // Refinements close button
        $('.container').on(
            'click',
            '.refinement-bar button.close, .modal-background',
            function () {
                $('.refinement-bar, .modal-background').removeClass('show');
                $('body').removeClass('filters-modal');
            }
        );
    };
}

/**
 * Close refinments click handler
 */
function viewItems() {
    $('.refinement-bar button.view-items-btn, .modal-background').unbind('click');
    baseSearch.viewItems = function () {
        // Refinements close button
        $('.container').on(
            'click',
            '.refinement-bar button.view-items-btn, .modal-background',
            function () {
                $('.refinement-bar, .modal-background').removeClass('show');
                $('body').removeClass('filters-modal');
            }
        );
    };
}

/**
 * Update the digital data fields after the page load.
 */
function updateDigitalData() {
    if (window.digitalData && window.digitalData.page && window.digitalData.page.attributes) {
        var pageAttributes = window.digitalData.page.attributes;
        if (pageAttributes.cdp && pageAttributes.cdp.term !== $.cookie('searchTerm')) {
            window.digitalData.page.attributes.cdp.term = $.cookie('searchTerm');
            delete $.cookie('searchTerm');
        } else if (pageAttributes.search && pageAttributes.search.term !== $.cookie('searchTerm')) {
            window.digitalData.page.attributes.search.term = $.cookie('searchTerm');
            delete $.cookie('searchTerm');
        }
    }
}

/**
 * Initiate facets expand collapse feature by adding session storage object
 */
function initExpandedFacets() {
    if (window.sessionStorage && !sessionStorage.expandedFacets) {
        var expandedFacets = {};
        $('.refinements .refinement').each(function (key, val) {
            expandedFacets[$(val).attr('id')] = 'false';
        });
        sessionStorage.setItem('expandedFacets', JSON.stringify(expandedFacets));
    }
}

/**
 * Updating facets expand collapse feature when clicking on it
 */
function handelExpandedFacets() {
    if (window.sessionStorage) {
        var expandedFacetsObj = JSON.parse(sessionStorage.expandedFacets);
        expandedFacetsObj['active-filters'] = 'true';
        $.each(expandedFacetsObj, function (key, valueObj) {
            var className = '.refinement-' + key;
            if (valueObj === 'false') {
                $(className).removeClass('active');
            } else {
                $(className).addClass('active');
            }
        });

        $('.refinement .card-header').on('click', function () {
            if (window.sessionStorage) {
                var activeFlag;
                if ($(this).parent('.refinement').hasClass('active')) {
                    activeFlag = 'false';
                } else {
                    activeFlag = 'true';
                }
                expandedFacetsObj[$(this).parents('.refinement').attr('id')] = activeFlag;
                sessionStorage.expandedFacets = JSON.stringify(expandedFacetsObj);
            }
        });
    }
}

/**
 * Initialize the recommended category filters for mobile
 */
function initCategoryFilters() {
    $('.cat-mob-filter').off().on('click', function () {
        recommendedFilters = true;
        $('.refinement-bar, .modal-background').addClass('show');
        $('body').addClass('filters-modal');
        var refinement = '#' + $(this).data('refinement');
        var $filter = $('.refinements').find(refinement);
        if (!$($filter[0]).hasClass('active')) {
            $($filter[0]).addClass('active');
        }
        $(refinement).get(0).scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    });
}

$(document).ready(function () {
    initExpandedFacets();
    handelExpandedFacets();
    updateDigitalData();
    initCategoryFilters();
    var search = location.search.substring(1);
    if (search !== '' && window.ffCdpOOSProduct) {
        var urlParams = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === '' ? value : decodeURIComponent(value); });
        urlParams = Object.entries(urlParams);
        var sizeParamsFlag = false;
        for (const [key, value] of urlParams) {
            if (value === 'size') {
                sizeParamsFlag = true;
                var paramPos = key.split('prefn')[1];
                for (var i = 0; i < urlParams.length; i++) {
                    if (urlParams[i][0] === 'prefv' + paramPos) {
                        selectedSizes = urlParams[i][1];
                        if (selectedSizes && selectedSizes.indexOf('|') > -1) {
                            selectedSizes = selectedSizes.replaceAll('|', ',');
                            break;
                        }
                    }
                }
            }
        }

        if (sizeParamsFlag) {
            updateOOSColorVariants($('.product-grid'));
        }
    }
});

/**
 * Resize handler
 */
function resize() {
    baseSearch.resize = function () {
        // Close refinement bar and hide modal background if user resizes browser
        $(window).resize(function () {
            if (!mobileMediaQuery.matches) {
                $('.refinement-bar, .modal-background').removeClass('show');
                $('body').removeClass('filters-modal');
            }
        });
    };
}

/**
 * CDP Show Loader
 */
function showLoader() {
    $('.cdp-loader').removeClass('hide');
    $('body').addClass('modal-with-backdrop');
}

/**
 * CDP Hide Loader
 */
function hideLoader() {
    $('.cdp-loader').addClass('hide');
    $('body').removeClass('modal-with-backdrop');
}

/**
 * See all for refinements
 */
function applyFilter() {
    baseSearch.applyFilter = function () {
        $(
            '.firstLevelFilter li a, .secondLevelFilter a, .mobFilterPills li a, .refinements li a, .refinement-bar a.reset, .filter-value a, .swatch-filter a'
        ).unbind('click');
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.firstLevelFilter li a, .secondLevelFilter a, .mobFilterPills li a, .refinements li a, .refinement-bar a.reset, .filter-value a, .swatch-filter a',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                showLoader();
                var pdPageWithQuickFilters = false;
                if ($('.firstLevelFilter').length > 0 || $('.secondLevelFilter').length > 0) {
                    pdPageWithQuickFilters = true;
                }
                if (!$(this).parents('.firstLevelFilter').length && !$(this).parents('.secondLevelFilter').length) {
                    var analyticsData = {
                        filterType: $(this).data('track-filter-type'),
                        filterValue: $(this).data('track-filter-value')
                    };

                    if (recommendedFilters) {
                        analyticsData.details = 'recommended';
                    }
                    if (typeof _satellite !== 'undefined') {
                        _satellite.track('filterInteraction', analyticsData);
                    }
                }
                if (
                    window.ffCdpStatePersist &&
                    'URLSearchParams' in window &&
                    typeof history.pushState !== 'undefined'
                ) {
                    let sizeValue;
                    let defaultSizeValue = 12;
                    let newRelativePathQuery;
                    if ($('.promo-tile-element').length > 0 && $('.grid-footer').data('only-second-promo') !== true) {
                        defaultSizeValue = 10;
                    }
                    sizeValue = defaultSizeValue;
                    if ($(this).data('hrefurl')) {
                        if ($(this).data('hrefurl').indexOf(location.origin) > -1) {
                            newRelativePathQuery = new URL($(this).data('hrefurl'));
                        } else {
                            newRelativePathQuery = new URL(location.origin + $(this).data('hrefurl'));
                        }
                        if (sizeValue) {
                            newRelativePathQuery.searchParams.set('sz', sizeValue);
                        }
                        if (newRelativePathQuery) {
                            history.pushState(null, '', newRelativePathQuery);
                        }
                    }
                }
                var filterUrl;
                if ($(this).data('hrefurl')) {
                    if ($(this).data('hrefurl').indexOf(location.origin) > -1) {
                        filterUrl = new URL($(this).data('hrefurl'));
                    } else {
                        filterUrl = new URL(location.origin + $(this).data('hrefurl'));
                    }
                } else {
                    filterUrl = new URL(location.origin + $(this).attr('href'));
                }
                var filterUrlSearchParams = filterUrl.searchParams;
                var searchParamsValuesEntries = filterUrlSearchParams.entries();
                var sizePreference = '';
                for (const [key, value] of searchParamsValuesEntries) {
                    if (value === 'size') {
                        sizePreference = key;
                        break;
                    }
                }
                var sizePreferenceValue = filterUrlSearchParams.get('prefv' + sizePreference.split('prefn')[1]);
                if (sizePreferenceValue && sizePreferenceValue.indexOf('|') > -1) {
                    sizePreferenceValue = sizePreferenceValue.replaceAll('|', ',');
                }
                selectedSizes = sizePreferenceValue;

                var mobile = false;
                if (mobileMediaQuery.matches) {
                    mobile = true;
                }
                var currentURL;
                if ($(this).data('hrefurl').indexOf(location.origin) > -1) {
                    currentURL = $(this).data('hrefurl');
                } else {
                    currentURL = location.origin + $(this).data('hrefurl');
                }
                currentURL = e.currentTarget.getAttribute('data-refinementurl') || currentURL;
                $.spinner().start();

                // reload the page when category filter selected
                if ($(this).data('filter-type') === 'category') {
                    window.location.reload();
                }

                $(this).trigger('search:filter', e);

                var targetApplyFilterLink = $(this);

                $.ajax({
                    url: currentURL,
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: currentURL,
                        mobileDevice: mobile,
                        pdPageWithQuickFilters: pdPageWithQuickFilters
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        initVisualCarousel.initCarousel();
                        handleCategories();
                        $.spinner().stop();
                        updateMetaDataTags(response);
                        $('.refinement-bar-sorting').remove();
                        if (firstLevelFilterEle.length > 0) {
                            handleQuickFilter(firstLevelFilterClass);
                        }

                        if (secondLevelFilterEle.length > 0) {
                            handleQuickFilter(secondLevelFilterClass);
                        }

                        // scroll to the product grid when filter applied
                        var filterPillHeight = mobile ? $('.mobFilterPills').height() : 0;
                        var stickyElementsHeight = $('.grid-header').outerHeight() + $('.navigation-container').height() + filterPillHeight;
                        $('html, body').animate({
                            scrollTop: $('.product-grid').offset().top - stickyElementsHeight
                        }, 700);

                        handleEinstein();
                        handelExpandedFacets();
                        handleWishlistStatus();
                        initCategoryFilters();
                        hideLoader();

                        if (targetApplyFilterLink.parents('.refinement').length > 0) {
                            setTimeout(function () {
                                $('.applied-filters')[0].focus();
                            }, 800);
                        }

                        // Handling compare checkboxes on filter apply
                        if (sessionStorage.getItem('savedProducts') !== null && sessionStorage.getItem('savedProducts') !== '') {
                            var preSelectedProds = JSON.parse(sessionStorage.getItem('savedProducts'));
                            preSelectedProds.forEach(function (prod) {
                                var pid = prod.pid;
                                if ($('input[id=' + pid + ']').length) {
                                    $('input[id=' + pid + ']')[0].checked = true;
                                }
                            });

                            if (preSelectedProds.length >= 2) {
                                $('.skip-link').removeClass('d-none');
                            }

                            if (preSelectedProds.length === 3) {
                                $('.compare input:not(:checked)').attr('disabled', 'disabled');
                            }
                        }

                        if ($('div.seo-rich-content')) {
                            var topContent = $('p.seo-rich-content');
                            styleSEOContent(topContent);
                        }
                        if ($('div.seo-bottom-rich-content')) {
                            var bottomContent = $('div.seo-bottom-rich-content');
                            styleSEOContent(bottomContent);
                        }
                        // Adding interval for the recommender to load before initializing carousel on remove filter
                        var counter = 0;
                        var timer = setInterval(function () {
                            if ($('.recommendations-cdp .carousel-container .recommendations').length) {
                                var $swatches = $('.recommendations-cdp .swatches');
                                carouselInit.initProductCarousel();
                                productTile.initSwatch($swatches);
                                clearInterval(timer);
                            }
                            counter++;
                            if (counter === 15) {
                                clearInterval(timer);
                            }
                        }, 800);
                    },
                    error: function () {
                        $.spinner().stop();
                        hideLoader();
                    }
                });
            }
        );
    };
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options')
        .options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * PLP Sorting
 */
function sort() {
    baseSearch.sort = function () {
        // Handle sort order menu selection
        $('.container').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleEinstein();
            showLoader();

            $.spinner().start();
            if (window.ffCdpStatePersist && 'URLSearchParams' in window && typeof history.pushState !== 'undefined') {
                var searchParams;
                var newRelativePathQuery;

                searchParams = new URLSearchParams(window.location.search);
                var srule = new URLSearchParams(this.value).get('srule');

                if (srule) {
                    searchParams.set('srule', srule);
                }
                newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();

                history.pushState(null, '', newRelativePathQuery);
            }
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    if (selectedSizes && window.ffCdpOOSProduct) {
                        updateOOSColorVariants($(response));
                    }
                    updateRefinementsPostSorting(response);

                    if ($('.refinement-bar-sorting').length > 0) {
                        $('.refinement-bar-sorting').remove();
                    }
                    $.spinner().stop();
                    hideLoader();
                },
                error: function () {
                    $.spinner().stop();
                    hideLoader();
                }
            });
        });
    };
}

/**
 * Show more product
 */
function showMore() {
    $('.container').unbind('click', '.show-more button');
    baseSearch.showMore = function () {
        $('.container').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            e.preventDefault();

            $('.more-number-of-items').hide();
            $('.see-more-button').hide();
            $('.more-loading-spinner').show();

            var showMoreUrl = $(this).data('url');


            if (
                window.ffCdpStatePersist &&
                'URLSearchParams' in window &&
                typeof history.pushState !== 'undefined'
            ) {
                var searchParams;
                var newRelativePathQuery;
                var searchSize;

                searchParams = new URLSearchParams(window.location.search);
                searchSize =
                    parseInt(
                        new URLSearchParams(new URL(showMoreUrl).search).get(
                            'sz'
                        ),
                        10
                    ) +
                    parseInt(
                        new URLSearchParams(new URL(showMoreUrl).search).get(
                            'start'
                        ),
                        10
                    );

                searchParams.set('sz', searchSize);
                newRelativePathQuery =
                    window.location.pathname + '?' + searchParams.toString();

                history.pushState(null, '', newRelativePathQuery);
            }
            var pdPageWithQuickFilters = false;
            if ($('.firstLevelFilter').length > 0 || $('.secondLevelFilter').length > 0) {
                pdPageWithQuickFilters = true;
            }

            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: {
                    selectedUrl: showMoreUrl,
                    pdPageWithQuickFilters: pdPageWithQuickFilters
                },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    if (selectedSizes && window.ffCdpOOSProduct) {
                        updateOOSColorVariants($(response));
                    }
                    $('.more-loading-spinner').hide();
                    $('.more-number-of-items').show();
                    $('.see-more-button').show();
                    addPaginationLinks(true);
                    handleWishlistStatus();
                }
            });
        });
    };
}

/**
 * Show more content in content search
 */
function showMoreContent() {
    $('.container').on('click', '.show-more-content button', function (e) {
        e.stopImmediatePropagation();
        var $element = $(this);
        var $target = $('#content-search-results .result-count');
        var showMoreUrl = $element.data('url');

        if (
            window.ffCdpStatePersist &&
            'URLSearchParams' in window &&
            typeof history.pushState !== 'undefined'
        ) {
            var searchParams;
            var newRelativePathQuery;
            var searchSize;

            searchParams = new URLSearchParams(window.location.search);
            searchSize = parseInt(
                new URLSearchParams(new URL(showMoreUrl).search).get(
                    'startingPage'
                ),
                10
            );

            searchParams.set('startingPage', searchSize);
            newRelativePathQuery =
                window.location.pathname + '?' + searchParams.toString();

            history.pushState(null, '', newRelativePathQuery);
        }

        $.ajax({
            url: showMoreUrl,
            method: 'GET',
            success: function (response) {
                $target.append(response);
                $('.show-more-content').remove();
                $('.result-count .col-12 .card').unwrap();
            }
        });
    });
}

$(document).on('click', '.search-nav .nav-link', function (e) {
    var searchParams;
    var newRelativePathQuery;

    searchParams = new URLSearchParams(window.location.search);
    searchParams.set(
        'view',
        e.currentTarget.className.indexOf('content-search') > -1 ?
        'content' :
        'product'
    );
    newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString();

    history.pushState(null, '', newRelativePathQuery);
});

/**
 * update product tile image on click and update variation price if required
 */
$(document).on('click keypress', 'img.cdp-swatches', function () {
    var imageContainer = $(this).closest('.product-tile').find('.image-container');
    var quickAddAnchor = $(this).closest('.product-tile').find('.quickview');
    var imageAnchorTag = imageContainer.find('a');
    var nameAnchor = $(this).closest('.product-tile').find('.pdp-link .link');
    var ratingAnchor = $(this).closest('.product-tile').find('.cdp-ratings');
    var imgDefault = imageContainer.find('.default-image');
    var imgHover = imageContainer.find('.hover-image');
    var selectedSwatch = $(this).closest('a');
    var selectedSwatchGroup = $(this).closest('.swatches');
    $(this).closest('.swatches').find('.selected').removeClass('selected')
    .attr('aria-current', 'false');
    selectedSwatch.addClass('selected').attr('aria-current', 'true');
    selectedSwatchGroup.find('a').not(selectedSwatch).removeClass('selected').attr('aria-current', 'false');

    var promoDiv = document.createElement('div');
    $(promoDiv).addClass('promoCallout');
    var hoverUrl = $(this).data('hover-url');
    var promoBadge = $(this).data('hover-promotag');
    var isLowInStock = $(this).data('islis');
    var lowInStockBadge = $(this).data('lis-msg');
    $.ajax({
        url: hoverUrl,
        method: 'GET',
        success: function (data) {
            var response = $(data).find('.image-container');
            var responseAnchor = response.find('a');
            var responseHrefLink = responseAnchor.attr('href');
            var dataLuluAttribute = responseAnchor.attr('data-lulu-attributes');

            var responseImgDefaultSrc = response.find('.default-image').attr('data-src');
            var responseImgDefaultSrcSet = response.find('.default-image')
                .parent('picture')
                .find('source')
                .attr('data-srcset');

            var responseHoverImg = response.find('.hover-image');
            var responseImgHoverSrc = responseHoverImg.attr('data-src');
            var responseImgHoverSrcSet = responseHoverImg
                .parent('picture')
                .find('source')
                .attr('data-srcset');
            $(this).closest('.product-tile').find('.image-container').find('a')
                .eq(1)
                .attr('href', responseHrefLink);

            var newQuickAddURL = $(data).find('.quickview').attr('href');
            quickAddAnchor.attr('href', newQuickAddURL);
            nameAnchor.attr('href', responseHrefLink);
            ratingAnchor.attr('href', responseHrefLink + '#BazaarVoice');
            imageAnchorTag.attr('href', responseHrefLink);
            imageAnchorTag.eq(0).attr('data-lulu-attributes', dataLuluAttribute);
            imgDefault.attr('data-src', responseImgDefaultSrc);
            imgDefault.attr('src', responseImgDefaultSrc);
            imgDefault.siblings('source').eq(0).attr('data-srcset', responseImgDefaultSrcSet);
            imgDefault.siblings('source').eq(1).attr('data-srcset', responseImgDefaultSrcSet.replace('800,800', '400,400'));
            imgDefault.siblings('source').eq(2).attr('data-srcset', responseImgDefaultSrcSet.replace('800,800 2x', '300,300'));
            imgDefault.siblings('source').eq(0).attr('srcset', responseImgDefaultSrcSet);
            imgDefault.siblings('source').eq(1).attr('srcset', responseImgDefaultSrcSet.replace('800,800', '400,400'));
            imgDefault.siblings('source').eq(2).attr('srcset', responseImgDefaultSrcSet.replace('800,800 2x', '300,300'));
            if (imageContainer.find('.promoCallout').length > 0) {
                imageContainer.find('.promoCallout').remove();
            }
            if (promoBadge) {
                promoDiv.innerText = promoBadge;
                imageContainer.append(promoDiv);
            } else if (promoDiv.length > 0) {
                promoDiv.css('display', 'none');
                $('.promoCallout').css('display', 'none');
            }
            if (isLowInStock) {
                imageContainer.find('.promoCallout').addClass('d-none');
                if (!imageContainer.find('.lis-badge').length) {
                    imageContainer.append('<div class="lis-badge">' + lowInStockBadge + '</div>');
                } else {
                    imageContainer.find('.lis-badge').removeClass('d-none');
                }
            } else {
                imageContainer.find('.lis-badge').addClass('d-none');
            }
            if (imgHover.length > 0 && responseHoverImg.length > 0) {
                // when both are present, simply replace
                imgHover.attr('src', responseImgHoverSrc);
                imgHover.attr('data-src', responseImgHoverSrc);
                imgHover.siblings('source').eq(0).attr('data-srcset', responseImgHoverSrcSet);
                imgHover.siblings('source').eq(1).attr('data-srcset', responseImgHoverSrcSet.replace('800,800', '400,400'));
                imgHover.siblings('source').eq(2).attr('data-srcset', responseImgHoverSrcSet.replace('800,800 2x', '300,300'));
                imgHover.siblings('source').eq(0).attr('srcset', responseImgHoverSrcSet);
                imgHover.siblings('source').eq(1).attr('srcset', responseImgHoverSrcSet.replace('800,800', '400,400'));
                imgHover.siblings('source').eq(2).attr('srcset', responseImgHoverSrcSet.replace('800,800 2x', '300,300'));
            } else if (!imgHover.length && responseHoverImg.length > 0) {
                // append hover-image from response
                imageAnchorTag.find('picture').append(responseHoverImg);
            }
        }
    });

    if ($(this).closest('.tile-body').find('.dynamicPricing').length) {
        var swatchSalePrice = $(this).closest('a').data('swatch-sale-price').trim();
        var swatchRegPrice = $(this).closest('a').data('swatch-list-price').trim();
        var rangePriceField = $(this).closest('.tile-body').find('.price-link .range');
        var strikeOutPriceField = $(this).closest('.tile-body').find('.dynamicPricing .strikedOutPrice')[0];
        var salePriceField = $(this).closest('.tile-body').find('.dynamicPricing .salePrice')[0];

        if (swatchRegPrice !== swatchSalePrice) {
            rangePriceField.hide();
            salePriceField.innerHTML = '<div class="sales"><span class="value" content="' + swatchSalePrice + '"><ins class="text-decoration-none">' + swatchSalePrice + '</ins></span></div>';
            strikeOutPriceField.innerHTML = '<del>' + swatchRegPrice + '</del>';
            $(this).closest('.tile-body').find('.price-vary-msg').hide();
        } else {
            rangePriceField.hide();
            salePriceField.innerHTML = '<div class="sales"><span class="value" content="' + swatchRegPrice + '"><ins class="text-decoration-none">' + swatchRegPrice + '</ins></span></div>';
            strikeOutPriceField.innerHTML = '';
            $(this).closest('.tile-body').find('.price-vary-msg').hide();
        }
    }
});

$(document).on('mouseenter focusin', '.product-tile .image-container', function () {
    var imgHover = $(this).find('.hover-image');
    imgHover.addClass('hovered lazyload').css('display', 'block');
});

$(document).on('mouseleave focusout', '.product-tile .image-container', function () {
    var imgHover = $(this).find('.hover-image');
    imgHover.removeClass('hovered');
});

$(document).on('wishlist:updateWishlistState', function () {
    handleWishlistStatus();
});

/**
 * Document ready function
 * @param {funtion} fn - return function
 */
function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function () {
    if ($('.wishlistitems').text().length > 0) {
        localStorage.setItem('wishlistItems', '');
        localStorage.setItem('wishlistItems', JSON.stringify($('.wishlistitems').text().split(',')));
        var wishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
        for (var i = 0; i < wishlistItems.length; i++) {
            var $tileElement = $('#' + wishlistItems[i]);
            $tileElement.find('.wishlistTile').addClass('remove-from-wishlist');
            $tileElement.find('.wishlistTile').attr('title', $tileElement.find('.wishlistTile').data('remove-wishlist'));
            $tileElement.find('.icon-wishlist').removeClass('icon-wishlist-o');
            if ($tileElement.hasClass('wishlist-icon-pdp')) {
                $tileElement.find('.wishlistTile').attr('href', $tileElement.find('.wishlistTile').data('remove-url'));
            }
            $tileElement.find('.icon-wishlist').addClass('icon-wishlist-s');
        }
    } else {
        localStorage.setItem('wishlistItems', JSON.stringify([]));
    }

    addPaginationLinks(false);
    // Handling the first level filter
    if (firstLevelFilterEle.length > 0) {
        handleQuickFilter(firstLevelFilterClass);
        $('.firstLevelFilter li:first-child a').click(function () {
            document.querySelector('meta[name="robots"]').setAttribute('content', 'index, follow, noodp, noydir');
        });
    }
    // Handling the second level filter
    if (secondLevelFilterEle.length > 0) {
        handleQuickFilter(secondLevelFilterClass);
        $('.secondLevelFilter .swiper-wrapper .experience-dynamic-filterTile:first-child a').click(function () {
            document.querySelector('meta[name="robots"]').setAttribute('content', 'index, follow, noodp, noydir');
        });
    }
});

module.exports = {
    openSeeAll: openSeeAll,
    applyFilter: applyFilter,
    filter: filter,
    closeRefinments: closeRefinments,
    viewItems: viewItems,
    resize: resize,
    showMoreContent: showMoreContent,
    showMore: showMore,
    sort: sort
};
