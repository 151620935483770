/* global _satellite */
'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
        if (icon.hasClass('icon-wishlist-o')) {
            icon.removeClass('icon-wishlist-o').addClass('icon-wishlist-s');
            $('.wishlistTile').blur();
        }
        if (window.cfCacheEnabled) {
            $.cookie('isDynamicData', true, { expires: 30, path: '/' });
        }
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages').append(
        '<div role="alert" aria-describedby="dialogWishlist" class="add-to-wishlist-alert text-center ' +
            status +
            '">' +
            ' <span id="dialogWishlist"> ' +
            data.msg +
            ' <span data-href="' +
            data.showWishlistURL +
            '" class="link" target="_blank" role="link">' + data.seeWishlistText + '</span>' +
            '</span>' +
            '</div>'
    );

    setTimeout(function () {
        $('.add-to-wishlist-messages').focus();
    }, 900);

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);

    $('.add-to-wishlist-alert a').on('click', function () {
        _satellite.track('wishlist-banner-link');
    });
}

/**
 * updates the state of wishlist button
 * @param {Object} currentElement - icon that was clicked to add a product to the wishlist
 * @param {string} data - data returned from the server's ajax call
 * @param {boolean} isRemoveProductFromWishlist flag to check if user has removed a product from wishlist
 * @param {boolean} isPDPWishlistIcon flag to check if user triggers wishlist action from PDP
 */
function updateRemoveFromWishlistIconState(currentElement, data, isRemoveProductFromWishlist, isPDPWishlistIcon) {
    if (data.success) {
        var wishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
        if (isRemoveProductFromWishlist) {
            var icon = currentElement.find($('i'));
            var productIDIndex = '';
            if (icon.hasClass('icon-wishlist-s')) {
                icon.removeClass('icon-wishlist-s').addClass('icon-wishlist-o');
                $('.wishlistTile').blur();
            }
            if (isPDPWishlistIcon) {
                currentElement.attr('href', data.addToWishlistURL);
                productIDIndex = wishlistItems.indexOf($('.product-detail input[name=productID]').val());
            } else {
                productIDIndex = wishlistItems.indexOf(currentElement.parents('.product-tile').attr('id'));
            }
            if (productIDIndex > -1) {
                wishlistItems.splice(productIDIndex, 1);
            }
            currentElement.removeClass('remove-from-wishlist');
            currentElement.attr('title', currentElement.data('add-wishlist'));
        } else {
            if (isPDPWishlistIcon) {
                currentElement.attr('href', data.removeFromWishlistURL);
            }
            wishlistItems.push(data.pid);
            currentElement.addClass('remove-from-wishlist');
            currentElement.attr('title', currentElement.data('remove-wishlist'));
        }

        localStorage.setItem('wishlistItems', JSON.stringify(wishlistItems));
    }
}

document.body.addEventListener('mousedown', function () {
    $('.wishlistTile').addClass('using-mouse');
});

document.body.addEventListener('keydown', function (event) {
    if (event.keyCode === 9) {
        $('.wishlistTile').removeClass('using-mouse');
    }
});

$(document).ready(function () {
    $('.wishlistTile').hover(function (e) {
        e.stopPropagation();
    });
});

$('.wishlistTile').bind('contextmenu', function () {
    this.blur();
});

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var isPDPWishlistIcon = $(e.target).parent('.wishlistTile').hasClass('main-prod-image') || $(e.target).parent('.wishlistTile').hasClass('main-prod-image-pdp');
            var currentElement = $(this);
            var methodType = 'post';
            var icon = currentElement.find($('i'));
            let pid;
            // checking if added from PDP
            const $pdpSku = $('.product-detail.skuAlignmentNewPDP .product-id');
            if ($pdpSku.length > 0 && currentElement.parents('.wishlist-icon-pdp').length > 0) {
                pid = $pdpSku[0].innerHTML;
            } else {
                pid = $(this)
                .closest('.product')
                .data('pid');
            }
            var optionId = $(this)
                .closest('.product-detail')
                .find('.product-option')
                .attr('data-option-id');
            var optionVal = $(this)
                .closest('.product-detail')
                .find('.options-select option:selected')
                .attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            var recommender = '';
            var wishlistAddRecommender = '';
            var wishlistRemoveRecommender = '';
            var wishlistAdd = '';
            var wishlistRemove = '';
            // Event type based on page
            if ($('.container .search-results').length) {
                wishlistAddRecommender = 'wishlist-add-recommender-cdp';
                wishlistRemoveRecommender = 'wishlist-remove-recommender-cdp';
                wishlistAdd = 'wishlist-add-cdp';
                wishlistRemove = 'wishlist-remove-cdp';
            }

            // Tracking clicks on CDP recommender product tiles.
            if ($(this).parents('.einstein-recommender').length) {
                recommender = 'cdp-recommendations-bestSellers';
            } else if ($(this).parents('.ai-recommender').length) {
                recommender = 'cdp-recommendations-bestSellers-ai';
            }

            var requestData;
            var isRemoveProductFromWishlist = currentElement.hasClass('remove-from-wishlist');
            var url = '';
            if (isPDPWishlistIcon) {
                url = $(this).attr('href');
                if (isRemoveProductFromWishlist) {
                    methodType = 'get';
                    requestData = {
                        optionId: optionId,
                        optionVal: optionVal
                    };
                } else {
                    requestData = {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal
                    };
                }
            } else {
                url = '';
                if (isRemoveProductFromWishlist) {
                    url = $(this).attr('data-remove-url');
                    methodType = 'get';
                    requestData = {
                        optionId: optionId,
                        optionVal: optionVal
                    };
                    if (recommender) {
                        _satellite.track(wishlistRemoveRecommender, {
                            recommenderName: recommender,
                            product: [{
                                productId: pid
                            }]
                        });
                    } else {
                        _satellite.track(wishlistRemove, {
                            product: [{
                                productId: pid
                            }]
                        });
                    }
                } else {
                    url = $(this).attr('data-add-url');
                    requestData = {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal
                    };
                    if (recommender) {
                        _satellite.track(wishlistAddRecommender, {
                            recommenderName: recommender,
                            product: [{
                                productId: pid
                            }]
                        });
                    } else {
                        _satellite.track(wishlistAdd, {
                            product: [{
                                productId: pid
                            }]
                        });
                    }
                }
            }

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: methodType,
                dataType: 'json',
                data: requestData,
                success: function (data) {
                    displayMessageAndChangeIcon(data, icon);
                    updateRemoveFromWishlistIconState(currentElement, data, isRemoveProductFromWishlist, isPDPWishlistIcon);
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon);
                }
            });
        });

        $('body').on('click', '.main-prod-image.wishlistTile', function (e) {
            e.preventDefault();
            var isPDPWishlistIcon = $(e.target).parent('.wishlistTile').hasClass('main-prod-image');
            var currentElement = $(this);
            var methodType = 'post';
            var icon = currentElement.find($('i'));
            var url = $(this).attr('href');
            var pid = $('input[name=productID]').val();

            if (!url || !pid) {
                return;
            }
            var requestData;
            var isRemoveProductFromWishlist = currentElement.hasClass('remove-from-wishlist');
            if (isRemoveProductFromWishlist) {
                methodType = 'get';
                requestData = {};
            } else {
                requestData = {
                    pid: pid
                };
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: methodType,
                dataType: 'json',
                data: requestData,
                success: function (data) {
                    displayMessageAndChangeIcon(data, icon);
                    updateRemoveFromWishlistIconState(currentElement, data, isRemoveProductFromWishlist, isPDPWishlistIcon);
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon);
                }
            });
        });
        $(document).on('click', '.add-to-wishlist-messages .link', function () {
            var url = $(this).attr('data-href');
            window.open(url, '_blank');
        });
    }
};
