'use strict';

require('../../icons/leftArrow.svg');
require('../../icons/rightArrow.svg');
var carousel = require('./carousel');
var productTile = require('./productTile');

var enableNewPdp = $('#pdp-redesign').val();
var slidesToShowCarousel = 1;
var centerModeCarousel = true;
var arrowsCarousel = true;
var scrollSlidesDesktop = 1;
var scrollSlidesMobile = 1;
var swipeToSlideFlag = true;

if (enableNewPdp === 'true') {
    slidesToShowCarousel = 2;
    centerModeCarousel = false;
    arrowsCarousel = false;
    scrollSlidesDesktop = 4;
    scrollSlidesMobile = 2;
    swipeToSlideFlag = false;
}

if ($('#home-page-indicator, #cdp-page-indicator, #catLanding-indicator, #pd-product-carousel').length >= 1) {
    slidesToShowCarousel = 2;
    centerModeCarousel = false;
    arrowsCarousel = false;
    scrollSlidesDesktop = 4;
    scrollSlidesMobile = 2;
    swipeToSlideFlag = false;
}

/**
 * Creates a product recommendations carousel
 */
function initProductCarousel() {
    var $productCarousel = $('.recommendations .product-carousel');
    if ($('.ai-recommendations .product-carousel').length) {
        $('.ai-recommendations .product-carousel').each(function () {
            $productCarousel.push(this);
        });
    }
    var opts = {
        slidesToShow: slidesToShowCarousel,
        slidesToScroll: scrollSlidesMobile,
        dots: true,
        customPaging: function (slider, i) {
            var ariaLable = 'slide ' + (i + 1) + ' of ' + (slider.getDotCount() + 1);
            return '<a href="javascript:void(0)" role="button" class="product-carousel-dots" id="' + i + '"aria-label="' + ariaLable + '" tabindex="0" ></a>';
        },
        centerMode: centerModeCarousel,
        centerPadding: '10%',
        arrows: arrowsCarousel,
        slide: 'div.product-carousel-slide',
        rows: 0,
        autoplay: false,
        swipeToSlide: swipeToSlideFlag,
        responsive: [
            {
                breakpoint: 1023.98,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: scrollSlidesDesktop,
                    arrows: true,
                    centerMode: false
                }
            }
        ]
    };
    // Removing the product slides with null product id's.
    var $productSlides = $('.product-carousel-slide').find('.product');
    for (var i = 0; i < $productSlides.length; i++) {
        if ($($productSlides[i]).data('pid') === null) {
            $($productSlides[i]).parent().remove();
        }
    }

    if ($($productCarousel.closest('.recommendations-cdp')).length) {
        opts.infinite = false;
    }

    $productCarousel.each(function () {
        let node = $(this);
        setTimeout(function () {
            carousel.initCarousel(opts, node);
            carousel.heightResize(node, 40, 'tile-body');
        }, 10);
    });

    //  add aria label for color swatches next prev arrows
    var colorSwatchPrevButton = document.querySelectorAll('.color-swatches .slick-prev');
    var colorSwatchNextButton = document.querySelectorAll('.color-swatches .slick-next');
    if (colorSwatchPrevButton.length > 0) {
        colorSwatchPrevButton.forEach((element) => element.setAttribute('aria-label', $(element).parent().data('swatch-button-label')));
    }
    if (colorSwatchNextButton.length > 0) {
        colorSwatchNextButton.forEach((element) => element.setAttribute('aria-label', $(element).parent().data('swatch-button-label')));
    }
}

/**
 * Initializes recommendation carousels when the product tiles are added using recommenders
 */
function reinitializeCarousel() {
    var recommendationWrappers = Array.from(document.querySelectorAll(
        '[id^=cq_recomm_slot]'
    )).filter(ele => !ele.closest('.recommendations-cdp')); // Excluding CDP recommenders
    var config = {
        attributes: false,
        childList: true,
        subtree: false
    };
    var observer = [];
    for (var index = 0; index < recommendationWrappers.length; index++) {
        var elem = recommendationWrappers[index];
        observer.push(
            new MutationObserver(function () {
                initProductCarousel();
                // Re-intializing color swatches after recommended products are added
                productTile.initSwatch();
            })
        );
        observer[index].observe(elem, config);
    }
}

/**
 * recommendation carousel lazyload script
 */
$(window).on('load', function () {
    var displayAllSlides = false;
    $('.recommendations .product-carousel').on('afterChange', function () {
        var lazyloadAllSlides = $('.recommendations .product-carousel-slide').not('.slick-cloned').find('.image-container').find('img')
        .hasClass('lazyload');
        if (!displayAllSlides) {
            $('.recommendations .product-carousel-slide.slick-current').find('.image-container').find('img').addClass('lazyloaded');
            $('.recommendations .product-carousel-slide').find('.image-container').find('img.lazyloaded').attr('data-lazyload', 'true');
            if (!lazyloadAllSlides) {
                $('.recommendations .product-carousel-slide').find('.image-container').find('img').attr('data-lazyload', 'true');
                displayAllSlides = true;
            }
        }
    });
});

module.exports = {
    initProductCarousel: initProductCarousel,
    reinitializeCarousel: reinitializeCarousel
};
