'use strict';

require('../../icons/spinner_34px.svg');
var debounce = require('lodash/debounce');
var util = require('../utilities/util');
var endpoint = $('.suggestions-wrapper').data('url');
var minBytes = 3;
var counterForLazyLoad = 0;
let mobileMediaQuery = window.matchMedia('(max-width: 1023px)');

var pdSwiperFilter = require('../components/pdSwiperFilter');
var lazyLoadConfigs = $('body').find('.lazy-load-configs');

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Updates the number of items value above the 'See More' button.
 */
function setMoreNumberOfItems() {
    var updatedProductItems = $('.product-grid .product-tile').length;
    $('.cdp-current-count').html(updatedProductItems);
}
/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body.type-ahead-suggestions').removeClass(
        'modal-open type-ahead-suggestions'
    );
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open type-ahead-suggestions');
        getSuggestionsWrapper(scope)
            .find('.suggestions')
            .addClass('modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile span.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch)
            .removeClass(iconSearch)
            .addClass(iconSearchClose);
    } else {
        $(mobileSearchIcon + iconSearchClose)
            .removeClass(iconSearchClose)
            .addClass(iconSearch);
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (
        $(scope).scrollTop() + $(scope).innerHeight() >=
        $(scope)[0].scrollHeight
    ) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;
    var calculatedHeight;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        calculatedHeight = 'calc(100% - ' + (top + outerHeight) + 'px)';
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css({
            top: top + outerHeight,
            bottom: 0,
            'max-height': calculatedHeight
        });

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    if (typeof response !== 'object') {
        $suggestionsWrapper.append(response).show();
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    var searchString = $(scope).val();
    var totalBytes;

    try {
        totalBytes = util.byteLengthOf(searchString);
    } catch (e) {
        totalBytes = searchString.length;
    }
    $.cookie('searchTerm', encodeURIComponent(searchString), { expires: 365, path: '/' });
    if (totalBytes >= minBytes) {
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}

/**
 * Adds products on CDP by lazy loading
 *
 */
function lazyLoadProducts() {
    var seeMoreURL;
    var index;
    var updatedURL;
    var productstolazyload = lazyLoadConfigs.data('productstolazyloaddesktop');
    var noofpagestolazyload = lazyLoadConfigs.data('noofpagestolazyload');
    if (mobileMediaQuery.matches) {
        productstolazyload = lazyLoadConfigs.data('productstolazyloadmobile');
    }
    if (counterForLazyLoad < noofpagestolazyload) {
        seeMoreURL = $('.see-more-button').data('url');
        index = seeMoreURL.indexOf('sz');
        if (index) {
            updatedURL = seeMoreURL.substring(0, index + 3) + productstolazyload;
            $('.see-more-button').data('url', updatedURL);
        }
        $('body').find('.see-more-button').click();
        counterForLazyLoad++;
        setMoreNumberOfItems();
    }
}

// Adding/removing page overlay during and after search
$(document).ready(function () {
    if ($('.newSearch').length > 0) {
        $('.newSearch').focusin(function () {
            $('.overlay-div').removeClass('d-none');
            $('.newSearch').addClass('focused');
        });

        $('.newSearch').focusout(function () {
            $('.overlay-div').addClass('d-none');
            $('.newSearch').removeClass('focused');
        });
    }

    $(window).on('orientationchange', function () {
        clearModals();
        $('.cross-icon-button').trigger('click');
        tearDownSuggestions();
    });
});

module.exports = function () {
    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);

        $(this).on('keyup click', function (e) {
            $('.site-search .cross-icon-button').toggleClass(
                'd-none',
                ($(this).val() === '' || $(this).val().trim() === '' || ($(this).val().trim().indexOf('$') >= 0) || ($(this).val().trim().indexOf('\\') >= 0))
            );

            if ($(this).val().trim() !== '') {
                debounceSuggestions(this, e);
            }

            $('.site-search .search-icon').toggleClass(
                'disabled',
                ($(this).val() === '' || $(this).val().trim() === '' || ($(this).val().trim().indexOf('$') >= 0) || ($(this).val().trim().indexOf('\\') >= 0))
            );
        });

        $(this).on('keydown', function (e) {
            if (e.keyCode === 13 && ($(this).val() === '' || $(this).val().trim() === '' || ($(this).val().trim().indexOf('$') >= 0) || ($(this).val().trim().indexOf('\\') >= 0))) {
                e.preventDefault();
            }
        });
    });

    $('body').on('click', function (e) {
        if (
            !$('.suggestions').has(e.target).length &&
            !$(e.target).hasClass('search-field') &&
            e.target !== $('.active.rounded-circle')[0]
        ) {
            $('.suggestions').hide();
        }
    });

    $(window).scroll(function () {
        var seeMoreButton = $('.see-more-button');
        if (seeMoreButton.length && window.activeScrollEvent) {
            var offsetHeight = $('.see-more-button').offset().top;
            var outerHeight = $('.see-more-button').outerHeight();
            var windowHeight = $(window).height();
            var wS = $(this).scrollTop();
            var noofpagestolazyload = lazyLoadConfigs.data('noofpagestolazyload');
            if (wS > (((offsetHeight + outerHeight) - windowHeight) / 2)) {
                if (!$('.see-more-button').hasClass('block-lazy-load')) {
                    $('.see-more-button').addClass('block-lazy-load');
                    lazyLoadProducts();
                } else if (!$('.see-more-button').hasClass('page-size-updated') && (counterForLazyLoad >= noofpagestolazyload)) {
                    var seeMoreURL = $('.see-more-button').data('url');
                    var updatedURL;
                    var updatedPageSize = lazyLoadConfigs.data('updatedpagesize');
                    var index = seeMoreURL.indexOf('sz');
                    if (index) {
                        updatedURL = seeMoreURL.substring(0, index + 3) + updatedPageSize + '&updatePageSize=true';
                        $('.see-more-button').attr('data-url', updatedURL);
                    }
                    $('.see-more-button').addClass('page-size-updated');
                }

                // Prevent multiple clicks on See more button
                $('.see-more-button').off('click').on('click', function () {
                    if ($('.see-more-button').hasClass('block-multiple-clicks')) {
                        $('.see-more-button').attr('disabled', 'disabled');
                    } else {
                        $('.see-more-button').addClass('block-multiple-clicks');
                    }
                });
            }

            localStorage.setItem('cdp-scroll-pos', $(window).scrollTop());
        }
    });

    $('body').on('touchend', function (e) {
        if (
            !$('.suggestions').has(e.target).length &&
            !$(e.target).hasClass('search-field')
        ) {
            $('.suggestions').hide();
            toggleSuggestionsIcon('search');
            tearDownSuggestions();
        }
    });

    $('body').on('click touchend', '.cross-icon-button', function () {
        $('.suggestions').hide();
        $('input.search-field').val('');
        tearDownSuggestions();
        $(this).addClass('d-none');
        if ($('.newSearch').length > 0) { // Keep focus on search bar when 'x' icon is clicked if newSearch class exists
            $('input.search-field').focus();
        }
    });

    /** The following monitors for changes in the number of products added
     ** to product grid and updates number of items info above the 'see more' button
     **/
    if ($('#product-search-results').length > 0 && $('.product-grid').length) {
        var productGrid = $('.product-grid')[0];

        var productGridObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var newNodes = mutation.addedNodes;
                if (newNodes !== null) {
                    setMoreNumberOfItems();
                }
            });
        });

        var productGridObserverConfig = {
            attributes: true,
            childList: true,
            characterData: true
        };

        productGridObserver.observe(productGrid, productGridObserverConfig);
    }

    pdSwiperFilter.init();

    $(window).on('load', function () {
        setTimeout(function () {
            if ($('.see-more-button').length > 0) {
                var queryString = window.location.search;
                var urlParams = new URLSearchParams(queryString);
                if (urlParams.get('sz') !== null) {
                    var productstolazyload = lazyLoadConfigs.data('productstolazyloaddesktop');
                    if (mobileMediaQuery.matches) {
                        productstolazyload = lazyLoadConfigs.data('productstolazyloadmobile');
                    }
                    counterForLazyLoad = urlParams.get('sz') / productstolazyload;

                    if (localStorage.getItem('cdp-scroll-pos') != null) {
                        $('html, body').stop().animate({ scrollTop: localStorage.getItem('cdp-scroll-pos') }, 500, 'swing', function () {
                            window.activeScrollEvent = true;
                        });
                    }
                } else {
                    window.activeScrollEvent = true;
                }
            }
        }, 300);

        $('.form-control.search-field').on('input', function () {
            var Searchquery = $(this).val();
            var suggestionsWrapper = $('.suggestions-wrapper');
            if (Searchquery.length > 3) {
                suggestionsWrapper.attr('aria-live', 'assertive');
            } else {
                suggestionsWrapper.removeAttr('aria-live');
            }
        });
    });
};
